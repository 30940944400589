import { useContext, useEffect, useState } from "react";
import { CityConfigContext, UserContext } from "../../index.jsx";
import { useNavigate } from "react-router-dom";
import { useTimerDispatch } from "../contexts/timerContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartSimple } from "@fortawesome/free-solid-svg-icons";
import CapitalIcon from "../../images/capitalIcon.png";
import OccupiedIcon from "../../images/OccupiedCityIcon.png";
export function CityList() {
  const { capitalCity, cityListState } = useContext(UserContext);
  const { cityName, inCityId } = useContext(CityConfigContext);

  const dispatch = useTimerDispatch();
  const { setShowSpinner } = useContext(UserContext);

  const navigate = useNavigate();

  async function changeCity(cityId) {
    const currentCityId = inCityId;
    setShowSpinner(true);
    await fetch(`${import.meta.env.VITE_GAMEHOST}/api/city/change`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        cityId: currentCityId,
        newCityId: cityId,
      }),
      credentials: "include",
    });

    /*     dispatch({
      type: timerActions.CLEAR_ALL,
    }); */
    navigate("/town");
  }

  if (!cityListState) {
    return <div>Loading..</div>;
  }

  useEffect(() => {
    function handleKeyPress(event) {
      const currentIndex = cityListState.findIndex(
        (city) => city.cityid === inCityId
      );

      if (currentIndex !== -1) {
        if (event.key === "ArrowDown") {
          const nextIndex = (currentIndex + 1) % cityListState.length; // Wraps around to the first city
          changeCity(cityListState[nextIndex].cityid);
        } else if (event.key === "ArrowUp") {
          const prevIndex =
            (currentIndex - 1 + cityListState.length) % cityListState.length; // Wraps around to the last city
          changeCity(cityListState[prevIndex].cityid);
        }
      }
    }

    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [navigate, inCityId]);

  return (
    <div className="bg-mainDarkBrown text-white p-2 min-w-[170px] sm:min-w-[170px] md:min-w-full lg:min-w-auto xl:min-w-auto z-[1000]">
      <div className="flex justify-center">
        <FontAwesomeIcon
          icon={faChartSimple}
          className="mr-2 hover:scale-[115%]"
          onClick={() => navigate("/cityStats")}
        />
        <h4 className="font-fantasy text-xs lg:text-sm xxl:text-lg text-white font-semibold mb-2 sm:mb-4 md:mb-4 lg:mb-4 xl:mb-4 text-center">
          {cityName}
        </h4>
      </div>
      <div className="max-h-[19vh] overflow-auto bg-mainLightBrown1 p-2 ">
        <ul className="space-y-1 sm:space-y-2 md:space-y-2 lg:space-y-2 xl:space-y-2">
          {cityListState.map((city, index) => (
            <li
              key={index}
              onClick={() => changeCity(city.cityid)}
              className="w-full bg-mainLightYellow2 min-w-[50px] text-gray-800 text-sm  md:text-xxs  xl:text-sm p-1  rounded-md hover:bg-mainLightYellowHover hover:text-white transition-colors duration-200 cursor-pointer"
            >
              <div className="flex flex-wrap items-center justify-start">
                <h2 className="whitespace-normal mr-2">{city.cityname}</h2>
                {capitalCity && city.cityid === capitalCity.capital_city_id && (
                  <img
                    src={CapitalIcon}
                    alt="capitalIcon"
                    className="w-[30px]"
                  />
                )}
                {city.isOccupied && (
                  <img
                    src={OccupiedIcon}
                    alt="Occupied city icon"
                    className="w-[30px]"
                  />
                )}
                {parseInt(inCityId) === city.cityid && (
                  <span className="inline-block bg-cyan-500 px-1 py-0.5 mr-2 rounded-full self-start">
                    Current
                  </span>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
