import { useEffect, useState } from "react";

export function HighScoreNavDropDown({
  options,
  onOptionChange,
  showOption,
  endgameInfo,
}) {
  const [stateOptions, setStateOptions] = useState(options);
  const [isOpen, setIsOpen] = useState(false);
  const [arrowSymbol, setArrowSymbol] = useState(0x25bc);

  // Find the option object that matches the selected value
  const selectedOption = options.find((option) => option.value === showOption);

  const handleOptionClick = (value) => {
    onOptionChange(value);
    setIsOpen(false);
  };

  useEffect(() => {
    if (!endgameInfo) {
      const filteredOptions = options.filter(
        (option) => option.value !== "topVictoryMonument"
      );
      setStateOptions(filteredOptions);
    }
  }, [selectedOption]);

  useEffect(() => {
    if (isOpen === true) {
      setArrowSymbol(0x25b2);
    } else {
      setArrowSymbol(0x25bc);
    }
  }, [isOpen]);

  return (
    <div className="relative xl:hidden z-[49]">
      <button
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className="py-2 px-3 bg-mainDarkBrown text-white min-w-[170px] rounded-none"
      >
        {selectedOption.text} {String.fromCharCode(arrowSymbol)}
      </button>
      {isOpen && (
        <ul className="absolute left-0   text-white shadow-xl min-w-[170px]">
          {stateOptions.map((option) => (
            <li key={option.value}>
              <button
                onClick={() => handleOptionClick(option.value)}
                className="block px-4 py-2 text-sm capitalize bg-mainDarkBrown hover:bg-blue-500 w-full rounded-none"
              >
                {option.text}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
