import { useEffect, useState } from "react";
import { WheatIconResponsive } from "../../images/resources/WheatIconResponsive";
import { WoodIconReponsive } from "../../images/resources/WoodIconReponsive";
import { StoneIconResponsive } from "../../images/resources/StoneIconResponsive";
import { IronIconResponsive } from "../../images/resources/IronIconResponsive";
import { RULE_CONFIG } from "../../RULES";
import { JadeIconResponsive } from "../../images/resources/JadeIconResponsive";

export function ConfirmResourcesModal(props) {
  const [tradersUsed, setTradersUsed] = useState(0);

  useEffect(() => {
    const sum =
      +props.wheat + +props.wood + +props.stone + +props.iron + +props.jade;
    setTradersUsed(Math.ceil(sum / RULE_CONFIG.merchantCarryAmount));
  }, []);

  return (
    <div className="fixed z-[1000] inset-0 overflow-y-auto text-white">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 transition-opacity"
          aria-hidden="true"
          onClick={props.closeModal}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75"> </div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div className="inline-block align-bottom bg-mainLightBrown4 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-mainLightBrown4 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <h3 className="text-lg leading-6 font-semibold text-center">
              Are you sure?
            </h3>
            <div className="mt-2">
              <p className="text-center mb-4">You're about to send:</p>

              <div className="grid grid-cols-2 place-items-center">
                <div className="flex">
                  <WheatIconResponsive />
                  <p className="ml-2"> {props.wheat}</p>
                </div>
                <div className="flex">
                  <WoodIconReponsive />
                  <p className="ml-2"> {props.wood}</p>
                </div>
                <div className="flex">
                  <StoneIconResponsive />
                  <p className="ml-2"> {props.stone}</p>
                </div>
                <div className="flex">
                  <IronIconResponsive />
                  <p className="ml-2"> {props.iron}</p>
                </div>
                <div className="flex">
                  <JadeIconResponsive />
                  <p className="ml-2"> {props.jade}</p>
                </div>
              </div>

              <p className="font-semibold text-center">
                Traders used: {tradersUsed}
              </p>
              <p className="font-semibold text-center">
                Traders left: {props.traders - tradersUsed}
              </p>
            </div>
          </div>
          <div className="bg-mainLightBrown4 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse md:flex md:flex justify-center">
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={props.confirm}
            >
              Confirm
            </button>
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-mainDarkBrown text-base font-medium text-white hover:bg-mainLightBrown3 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
              onClick={props.closeModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
