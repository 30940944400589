import { useContext, useEffect, useState } from "react";
import { GameOverHeader } from "../Components/bars/GameOverHeader";
import { SignedOutsNavBar } from "../Components/bars/SignedOutsNavBar";
import { FixedLayout } from "../FixedLayout";
import { AuthContext, UserContext } from "..";
import { useNavigate } from "react-router-dom";
import { WinnerDisplay } from "../Components/WinnerDisplay";

export function GameOverPage() {
  const { authToken } = useContext(AuthContext);
  const { gameOver } = useContext(UserContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (!gameOver && gameOver !== undefined) {
      navigate("/");
    }
  }, [gameOver]);

  return (
    <div className="h-screen w-screen flex flex-col bg-gradient-to-b from-mainLightGreen to-[#d4a373]">
      {authToken ? (
        gameOver ? (
          <GameOverHeader />
        ) : (
          <FixedLayout />
        )
      ) : (
        <SignedOutsNavBar />
      )}

      <div className=" flex justify-center  text-black ">
        <div className="   bg-gradient-to-r from-mainAttack2 to-mainAttack3 p-6 rounded-lg shadow-md mt-16 mx-4 max-h-[90vh] overflow-y-scroll">
          <WinnerDisplay />
        </div>
      </div>
    </div>
  );
}
