import { motion } from "framer-motion";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CityConfigContext } from "../..";

export function LootChestPrizeModal({ prize, closeModal }) {
  const { cityTokens } = useContext(CityConfigContext);
  const navigate = useNavigate();

  return (
    <motion.div
      className="fixed z-50 top-0 left-0 w-full h-full flex items-center justify-center text-white"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="relative bg-mainLightBrown4 p-5 rounded-lg shadow-lg text-center">
        <button
          className="absolute top-2 right-2 text-xs text-white bg-gray-800 hover:text-gray-300 focus:outline-none"
          onClick={() => navigate("/town")}
        >
          X
        </button>
        <h2 className=" mt-4 text-2xl font-bold mb-4">Unclaimed Prize</h2>
        <p className="text-xl">{prize.description}</p>

        <div className="flex justify-between">
          <button
            className="mt-4 px-4 py-2 bg-mainDarkBrown3 text-white rounded bg-gray-800"
            onClick={() => closeModal("claim")}
          >
            Claim
          </button>
          {cityTokens.chest_reroll_count > 0 && (
            <div className="flex">
              <button
                onClick={() => closeModal("reroll")}
                className="bg-gray-800"
              >
                Reroll
              </button>
              <div>{cityTokens.chest_reroll_count}/1</div>
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
}
