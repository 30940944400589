import { motion } from "framer-motion";
import { useContext } from "react";
import { CityConfigContext } from "../../index";

export function NewLootChestModal() {
  const { setShowNewLootChestModal } = useContext(CityConfigContext);

  const closeModal = () => {
    setShowNewLootChestModal(false);
  };

  return (
    <motion.div
      className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center text-black"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={closeModal} // Close modal when clicking outside
    >
      <motion.div
        className="fixed inset-0 transition-opacity"
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.75 }}
        exit={{ opacity: 0 }}
      >
        <div className="absolute inset-0 bg-gray-500"></div>
      </motion.div>

      <motion.div
        className="bg-white rounded-lg shadow-lg z-50 max-w-xs w-full p-8 overflow-y-auto"
        initial={{ y: "-100vh" }}
        animate={{ y: "0" }}
        transition={{ stiffness: 150 }}
        onClick={(e) => e.stopPropagation()} // Prevent click from closing modal when clicking inside
      >
        <div className="flex justify-between items-center pb-3">
          <p className="text-2xl font-bold">Congratulations!</p>
          <div className="cursor-pointer z-50" onClick={closeModal}>
            <svg
              className="fill-current text-black"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
            </svg>
          </div>
        </div>

        <p>A new lootchest has been added to your casino inventory!</p>
      </motion.div>
    </motion.div>
  );
}
