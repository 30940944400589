import { useContext } from "react";
import { AuthContext, UserContext } from "..";
import { FixedLayout } from "../FixedLayout";
import { GameOverHeader } from "./bars/GameOverHeader";

export function LayoutSelector({ children }) {
  const { authToken } = useContext(AuthContext);
  const { gameOver } = useContext(UserContext);
  if (gameOver === undefined) {
    return <div>Loading...</div>;
  }

  return gameOver ? (
    <>
      <GameOverHeader /> {children}
    </>
  ) : (
    <FixedLayout>{children}</FixedLayout>
  );
}
