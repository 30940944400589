import { useEffect, useState } from "react";
import CrownIcon from "../images/nameIcons/crown.png";
import AdminIcon from "../images/nameIcons/admin.png";

export function WinnerDisplay() {
  const [winners, setWinners] = useState();

  async function getWinners() {
    const res = await fetch(
      `${import.meta.env.VITE_GAMEHOST}/api/highscores/winners`,
      {
        credentials: "include",
      }
    );

    if (res.status === 200) {
      const data = await res.json();
      setWinners(data);
    }
  }

  useEffect(() => {
    getWinners();
  }, []);
  const ICON_MAP = {
    Crown: CrownIcon,
    Admin: AdminIcon,
  };

  if (!winners) {
    return <div>Loading...</div>;
  }

  return (
    <div className="max-w-4xl mx-auto p-4 sm:p-6 bg-gradient-to-br from-blue-50 to-purple-50 rounded-xl shadow-sm">
      {/* Main Heading */}
      <h4 className="font-bold text-3xl sm:text-4xl md:text-5xl text-center mb-4 sm:mb-6 bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
        Siege {winners.season} Round is Over!
      </h4>

      {/* Winners Subheading */}
      <h4 className="text-2xl sm:text-3xl font-semibold text-center mb-6 text-gray-800">
        Winners
      </h4>

      {/* Winners List */}
      <ul className="space-y-4 max-h-[40vh] md:max-h-[60vh] overflow-y-scroll">
        {winners.winners.map((winner, index) => (
          <li
            key={index}
            className="bg-white p-4 sm:p-6 rounded-xl shadow-md hover:shadow-lg transition-shadow border border-gray-100"
          >
            <h4 className="text-center">{winner.category_name}</h4>
            <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row justify-between items-start sm:items-center">
              {/* Winner Details */}
              <div>
                <p className="text-xl sm:text-2xl font-bold text-gray-900">
                  {winner.username}
                </p>
                <p className="text-lg sm:text-xl text-gray-600">
                  <span className="font-medium">
                    {winner.category_description}
                  </span>
                </p>
              </div>
            </div>
            <div>
              <h4>Rewards:</h4>

              {winner.title_name && (
                <div>
                  title: {winner.username} {winner.title_name}
                </div>
              )}
              {winner.color && (
                <div className="flex ">
                  <div>Name color: </div>
                  <div className="font-bold" style={{ color: winner.color }}>
                    {winner.username}
                  </div>
                </div>
              )}

              {winner.icon_name && ICON_MAP[winner.icon_name] && (
                <div className="flex">
                  <div>Icon: </div>
                  <img
                    src={ICON_MAP[winner.icon_name]}
                    alt="Name reward icon"
                    className="w-6 h-6 ml-2"
                  />
                </div>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
