import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useState } from "react";
import { useCookies } from "react-cookie";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { AuthContext } from "../..";
import { faBullhorn } from "@fortawesome/free-solid-svg-icons";
import SignOutIcon from "../../images/navigations/SignOutNav.png";
import { HighscoreNavigationImage } from "../../images/HighscoreNavigationImage";

export function GameOverHeader() {
  const { setAuthToken } = useContext(AuthContext);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [isLeftMenuOpen, setLeftMenuOpen] = useState(false);
  const navigate = useNavigate();
  const [cookies, setCookies, removeCookies] = useCookies(["authToken"]);
  async function logOut() {
    const res = await fetch(
      `${import.meta.env.VITE_GAMEHOST}/api/auth/logout`,
      {
        credentials: "include",
      }
    );

    if (res.ok) {
      setAuthToken(undefined);
      removeCookies("authToken");
      navigate("/login");
    } else {
      removeCookies("authToken");
    }

    localStorage.clear();
  }

  return (
    <div className="h-auto top-0 w-screen fixed z-50">
      {/* TOP BAR */}
      <div className="bg-resCounterBg rounded-md text-xxs xs:text-xs sm:text-sm ">
        <div className="flex justify-end">
          <div className=" transition duration-300 ease-in-out cursor-pointer grid grid-cols-2 ">
            <div className="hover:bg-blue-700 flex justify-center place-items-center text-center">
              <Link to={`/news`} className="text-blue-400 ">
                <FontAwesomeIcon
                  icon={faBullhorn}
                  className="mr-2 fa-regular"
                />
              </Link>
            </div>
            <p className="flex items-center p-2 text-gray-800 hover:bg-red-700">
              <img
                src={SignOutIcon}
                className="mr-2 w-8"
                alt="Sign out icon"
                onClick={logOut}
              />
            </p>
          </div>
        </div>
      </div>

      {/* LEFT MENU */}
      <div className={`fixed top-20 flex bg-gray-500 p-4`}>
        <div
          className=" hover:scale-[106%]"
          onClick={() => navigate("/highscores")}
        >
          <HighscoreNavigationImage />
        </div>
      </div>
    </div>
  );
}
