import { AxemanResponsive } from "../../images/troops/AxemanResponsive";
import { CatapultResponsive } from "../../images/troops/CatapultResponsive";
import { ClubmanResponsive } from "../../images/troops/ClubmanResponsive";
import { ConquererResponsive } from "../../images/troops/ConquererResponsive";
import { FounderIconResponsive } from "../../images/troops/FounderIconResponsive";
import { GuardianResponsive } from "../../images/troops/GuardianResponsive";
import { ScoutResponsive } from "../../images/troops/ScoutResponsive";
import { SpearmanResponsive } from "../../images/troops/SpearmanResponsive";
import { WarlordResponsive } from "../../images/troops/WarlordResponsive";

export function CombatCalculatorResult({ result }) {
  return (
    <div>
      <div className="hidden xl:block">
        <div className="grid grid-cols-1 gap-8">
          <div className="flex flex-col items-center">
            <table className="table-auto  mb-2 border-collapse">
              <thead className="bg-mainLightBrown1">
                <tr>
                  <th
                    className={`border border-black px-2 md:px-4 
  
                            bg-mainAttack3
                         text-white`}
                  >
                    Attacker
                  </th>
                </tr>
                <tr className="">
                  <th className="border border-black px-2 md:px-4">
                    <h3 className=" font-semibold mb-2 text-lg text-blue-600">
                      Attacker
                    </h3>
                  </th>
                  <th className="border border-black  px-2 md:px-4">
                    <ClubmanResponsive />
                  </th>
                  <th className="border border-black px-2 md:px-4">
                    <SpearmanResponsive />
                  </th>
                  <th className="border border-black px-2 md:px-4">
                    <AxemanResponsive />
                  </th>
                  <th className="border border-black px-2 md:px-4">
                    <FounderIconResponsive />
                  </th>
                  <th className="border border-black px-2 md:px-4">
                    <GuardianResponsive />
                  </th>
                  <th className="border border-black px-2 md:px-4">
                    <WarlordResponsive />
                  </th>
                  <th className="border border-black px-2 md:px-4">
                    <ScoutResponsive />
                  </th>
                  <th className="border border-black px-2 md:px-4">
                    <CatapultResponsive />
                  </th>
                  <th className="border border-black px-2 md:px-4">
                    <ConquererResponsive />
                  </th>
                </tr>
              </thead>
              <tbody className="bg-mainLightBrown1">
                <tr>
                  <td className="border border-black px-2 md:px-4">Troops</td>
                  {Object.entries(result.attackerTroops).map(
                    ([troop, amount]) => (
                      <td
                        key={troop}
                        className="text-center border border-black px-2 md:px-4"
                      >
                        {amount}
                      </td>
                    )
                  )}
                </tr>

                <tr>
                  <td className="border border-black px-2 md:px-4">
                    Casualties
                  </td>
                  {Object.entries(result.attackerCasualties).map(
                    ([troop, amount]) => (
                      <td
                        key={troop}
                        className="text-center border border-black px-2 md:px-4"
                      >
                        {amount}
                      </td>
                    )
                  )}
                </tr>
              </tbody>
            </table>
          </div>

          {/*DEFENDER TABLE*/}

          <div className="flex flex-col items-center">
            <table className="table-auto  mb-6 border-collapse ">
              <thead className="bg-mainLightBrown1">
                <tr>
                  <th className="border border-black px-2 md:px-4 bg-mainDefend3 text-white">
                    Defender
                  </th>
                </tr>
                <tr>
                  <th className="border border-black px-2 md:px-4">
                    <h3 className="font-semibold mb-2 text-lg text-blue-600">
                      Defender
                    </h3>
                  </th>
                  <th className="border border-black px-2 md:px-4">
                    <ClubmanResponsive />
                  </th>
                  <th className="border border-black px-2 md:px-4">
                    <SpearmanResponsive />
                  </th>
                  <th className="border border-black px-2 md:px-4">
                    <AxemanResponsive />
                  </th>
                  <th className="border border-black px-2 md:px-4">
                    <FounderIconResponsive />
                  </th>
                  <th className="border border-black px-2 md:px-4">
                    <GuardianResponsive />
                  </th>
                  <th className="border border-black px-2 md:px-4">
                    <WarlordResponsive />
                  </th>
                  <th className="border border-black px-2 md:px-4">
                    <ScoutResponsive />
                  </th>
                  <th className="border border-black px-2 md:px-4">
                    <CatapultResponsive />
                  </th>
                  <th className="border border-black px-2 md:px-4">
                    <ConquererResponsive />
                  </th>
                </tr>
              </thead>
              <tbody className="bg-mainLightBrown1">
                <tr>
                  <td className=" border border-black px-2 md:px-4">Troops</td>
                  {Object.entries(result.defenderTroops).map(
                    ([troop, amount]) => (
                      <td
                        key={troop}
                        className=" text-center border border-black px-2 md:px-4"
                      >
                        {amount === null ? "????" : amount}
                      </td>
                    )
                  )}
                </tr>
                <tr>
                  <td className="border border-black px-2 md:px-4">
                    Casualties
                  </td>
                  {Object.entries(result.defenderCasualties).map(
                    ([troop, amount]) => (
                      <td
                        key={troop}
                        className=" text-center border border-black px-2 md:px-4"
                      >
                        {amount === null ? "????" : amount}
                      </td>
                    )
                  )}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* small screens */}
      <div className="block xl:hidden">
        {/*ATTACK CARD*/}
        <div
          className={`
          bg-mainAttack3
            p-2`}
        >
          <h4 className="text-white text-center ">Attacker</h4>
          <div className="bg-mainLightBrown1 text-center p-2 rounded">
            <h4 className="mb-2 font-semibold text-blue-600">Attacker</h4>

            <h4>Casualties/Troops</h4>
            <div className="grid grid-cols-2 place-items-center">
              <div className="flex">
                <ClubmanResponsive />
                <h4 className="ml-2">
                  {result.attackerCasualties.troop1}/
                  {result.attackerTroops.troop1}
                </h4>
              </div>
              <div className="flex">
                <SpearmanResponsive />
                <h4 className="ml-2">
                  {result.attackerCasualties.troop2}/
                  {result.attackerTroops.troop2}
                </h4>
              </div>
              <div className="flex">
                <AxemanResponsive />
                <h4 className="ml-2">
                  {result.attackerCasualties.troop3}/
                  {result.attackerTroops.troop3}
                </h4>
              </div>
              <div className="flex">
                <FounderIconResponsive />
                <h4 className="ml-2">
                  {result.attackerCasualties.troop4}/
                  {result.attackerTroops.troop4}
                </h4>
              </div>
              <div className="flex">
                <GuardianResponsive />
                <h4 className="ml-2">
                  {result.attackerCasualties.troop5}/
                  {result.attackerTroops.troop5}
                </h4>
              </div>
              <div className="flex">
                <WarlordResponsive />
                <h4 className="ml-2">
                  {result.attackerCasualties.troop6}/
                  {result.attackerTroops.troop6}
                </h4>
              </div>
              <div className="flex">
                <ScoutResponsive />
                <h4 className="ml-2">
                  {result.attackerCasualties.troop7}/
                  {result.attackerTroops.troop7}
                </h4>
              </div>
              <div className="flex">
                <CatapultResponsive />
                <h4 className="ml-2">
                  {result.attackerCasualties.troop8}/
                  {result.attackerTroops.troop8}
                </h4>
              </div>
              <div className="flex">
                <ConquererResponsive />
                <h4 className="ml-2">
                  {result.attackerCasualties.troop9}/
                  {result.attackerTroops.troop9}
                </h4>
              </div>
            </div>
          </div>
        </div>

        {/*DEFENDER CARD*/}

        <div className="bg-mainDefend3 mt-4 p-2">
          <h4 className="text-center text-white">Defender</h4>

          <div className="text-center bg-mainLightBrown1 p-2 rounded">
            <h4 className="mb-2 font-semibold  text-blue-600">Defender</h4>
            <h4>Casualties/Troops</h4>
            <div className="grid grid-cols-2 place-items-center">
              <div className="flex">
                <ClubmanResponsive />
                <h4 className="ml-2">
                  {result.defenderCasualties.troop1 !== null ? (
                    <>
                      {result.defenderCasualties.troop1}/
                      {result.defenderTroops.troop1}
                    </>
                  ) : (
                    "????"
                  )}
                </h4>
              </div>
              <div className="flex">
                <SpearmanResponsive />
                <h4 className="ml-2">
                  {result.defenderCasualties.troop2 !== null ? (
                    <>
                      {result.defenderCasualties.troop2}/
                      {result.defenderTroops.troop2}
                    </>
                  ) : (
                    "????"
                  )}
                </h4>
              </div>
              <div className="flex">
                <AxemanResponsive />
                <h4 className="ml-2">
                  {result.defenderCasualties.troop3 !== null ? (
                    <>
                      {result.defenderCasualties.troop3}/
                      {result.defenderTroops.troop3}
                    </>
                  ) : (
                    "????"
                  )}
                </h4>
              </div>
              <div className="flex">
                <FounderIconResponsive />
                <h4 className="ml-2">
                  {result.defenderCasualties.troop4 !== null ? (
                    <>
                      {result.defenderCasualties.troop4}/
                      {result.defenderTroops.troop4}
                    </>
                  ) : (
                    "????"
                  )}
                </h4>
              </div>
              <div className="flex">
                <GuardianResponsive />
                <h4 className="ml-2">
                  {result.defenderCasualties.troop5 !== null ? (
                    <>
                      {result.defenderCasualties.troop5}/
                      {result.defenderTroops.troop5}
                    </>
                  ) : (
                    "????"
                  )}
                </h4>
              </div>
              <div className="flex">
                <WarlordResponsive />
                <h4 className="ml-2">
                  {result.defenderCasualties.troop6 !== null ? (
                    <>
                      {result.defenderCasualties.troop6}/
                      {result.defenderTroops.troop6}
                    </>
                  ) : (
                    "????"
                  )}
                </h4>
              </div>
              <div className="flex">
                <ScoutResponsive />
                <h4 className="ml-2">
                  {result.defenderCasualties.troop7 !== null ? (
                    <>
                      {result.defenderCasualties.troop7}/
                      {result.defenderTroops.troop7}
                    </>
                  ) : (
                    "????"
                  )}
                </h4>
              </div>
              <div className="flex">
                <CatapultResponsive />
                <h4 className="ml-2">
                  {result.defenderCasualties.troop8 !== null ? (
                    <>
                      {result.defenderCasualties.troop8}/
                      {result.defenderTroops.troop8}
                    </>
                  ) : (
                    "????"
                  )}
                </h4>
              </div>

              <div className="flex">
                <ConquererResponsive />
                <h4 className="ml-2">
                  {result.defenderCasualties.troop9 !== null ? (
                    <>
                      {result.defenderCasualties.troop9}/
                      {result.defenderTroops.troop9}
                    </>
                  ) : (
                    "????"
                  )}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
