import { useContext, useEffect } from "react";
import { AuthContext, UserContext } from "..";
import { Navigate, Outlet } from "react-router-dom";
import { LayoutSelector } from "./LayoutSelector";

export function AuthRoute() {
  const { authToken, roundEnded } = useContext(AuthContext);
  const { gameOver } = useContext(UserContext);
  const path = window.location.pathname;

  if (authToken === undefined) {
    return null;
  }

  if (authToken === null) {
    return <Navigate to="/login" replace />;
  }

  if (gameOver === true && path !== "/highscores") {
    return <Navigate to="/gameOver" replace />;
  }

  return (
    <LayoutSelector>
      <Outlet />
    </LayoutSelector>
  );
}
