import CrownIcon from "../images/nameIcons/crown.png";
import AdminIcon from "../images/nameIcons/admin.png";

export function StyledUser({ username, nameColor, nameIcon, nameTitle }) {
  const ICON_MAP = {
    Crown: CrownIcon,
    Admin: AdminIcon,
  };

  return (
    <div className="flex">
      <h4 style={{ color: nameColor }} className="break-all ">
        {username} {nameTitle}
      </h4>
      {nameIcon && ICON_MAP[nameIcon] && (
        <img
          src={ICON_MAP[nameIcon]}
          alt="Name reward icon"
          className="w-6 h-6 ml-2"
        />
      )}
    </div>
  );
}
