import { useEffect, useState } from "react";
import { StyledUser } from "./StyledUser";

export function SelectNameIconComponent({
  selectedNameColor,
  selectedNameIcon,
  setSelectedNameIcon,
  availableIcons,
  username,
}) {
  const [allIcons, setAllIcons] = useState(availableIcons);

  useEffect(() => {
    if (availableIcons.length > 0 && allIcons.length <= availableIcons.length) {
      const find = allIcons.find((ic) => ic === "null");
      if (!find) {
        allIcons.push("null");
      }
    }
  }, [availableIcons]);
  return (
    <div>
      <h4 className="mb-2 text-lg font-semibold text-gray-50">
        Choose name icon:
      </h4>
      {allIcons.length > 0 ? (
        <div className="flex">
          {allIcons.map((icon) => (
            <div
              className={`${
                icon === selectedNameIcon && "border-2 border-black rounded-lg "
              }
             ${
               icon === "null" &&
               selectedNameIcon === null &&
               "border-2 border-black rounded-lg "
             }  p-2 font-semibold`}
              onClick={() => setSelectedNameIcon(icon)}
            >
              <StyledUser
                username={username}
                nameColor={selectedNameColor}
                nameIcon={icon}
              />
            </div>
          ))}
        </div>
      ) : (
        <div>No available icons</div>
      )}
    </div>
  );
}
