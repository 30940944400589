import { useContext, useEffect } from "react";
import { JoinRoundComponent } from "../Components/JoinRoundComponent";
import { AuthContext } from "..";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

export function JoinRoundPage() {
  const pageUrl = window.location.href;
  const { needsToJoin, setAuthToken } = useContext(AuthContext);
  const [cookies, setCookies, removeCookies] = useCookies(["authToken"]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!needsToJoin) {
      window.location.replace(`${import.meta.env.VITE_FRONTEND}/resources`);
    }
  }, [pageUrl]);

  async function logOut() {
    const res = await fetch(
      `${import.meta.env.VITE_GAMEHOST}/api/auth/logout`,
      {
        credentials: "include",
      }
    );

    if (res.ok) {
      setAuthToken(undefined);
      removeCookies("authToken");
      window.location.reload();
    } else {
      removeCookies("authToken");
    }

    localStorage.clear();
  }

  return (
    <div className="h-screen w-screen flex flex-col bg-gradient-to-b from-mainLightGreen to-[#d4a373]">
      <div className="fixed right-0 top-0">
        <button onClick={logOut}>Log out</button>
      </div>
      <div className=" flex items-center justify-center h-full text-black">
        <JoinRoundComponent />
      </div>
    </div>
  );
}
