import { AxemanResponsive } from "../../images/troops/AxemanResponsive";
import { CatapultResponsive } from "../../images/troops/CatapultResponsive";
import { ClubmanResponsive } from "../../images/troops/ClubmanResponsive";
import { ConquererResponsive } from "../../images/troops/ConquererResponsive";
import { FounderIconResponsive } from "../../images/troops/FounderIconResponsive";
import { GuardianResponsive } from "../../images/troops/GuardianResponsive";
import { ScoutResponsive } from "../../images/troops/ScoutResponsive";
import { SpearmanResponsive } from "../../images/troops/SpearmanResponsive";
import { WarlordResponsive } from "../../images/troops/WarlordResponsive";

export function CalculatorTroopInfo({ troops, setTroops, setBonus, bonus }) {
  function handleTroopChange(troop, value) {
    setTroops((prevState) => ({
      ...prevState,
      [troop]: Number(value),
    }));
  }
  function handleBonusChange(e) {
    setBonus(e.target.value);
  }

  return (
    <div>
      <div className="grid grid-cols-3 lg:grid-cols-6 2xl:grid-cols-9 gap-2">
        <div className="flex flex-col gap-1 place-items-center ">
          <ClubmanResponsive />
          <input
            type="number"
            value={troops.troop1}
            className="text-white w-16 bg-calculatorInputBG p-1 border-mainAttack border-2"
            onChange={(e) => handleTroopChange("troop1", e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-1 place-items-center">
          <SpearmanResponsive />
          <input
            type="number"
            value={troops.troop2}
            className="text-white w-16 bg-calculatorInputBG p-1 border-mainAttack border-2"
            onChange={(e) => handleTroopChange("troop2", e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-1 place-items-center">
          <AxemanResponsive />
          <input
            type="number"
            value={troops.troop3}
            className="text-white w-16 bg-calculatorInputBG p-1 border-mainAttack border-2"
            onChange={(e) => handleTroopChange("troop3", e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-1 place-items-center">
          <FounderIconResponsive />
          <input
            type="number"
            value={troops.troop4}
            className="text-white w-16 bg-calculatorInputBG p-1 border-mainAttack border-2"
            onChange={(e) => handleTroopChange("troop4", e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-1 place-items-center">
          <GuardianResponsive />
          <input
            type="number"
            value={troops.troop5}
            className="text-white w-16 bg-calculatorInputBG p-1 border-mainAttack border-2"
            onChange={(e) => handleTroopChange("troop5", e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-1 place-items-center">
          <WarlordResponsive />
          <input
            type="number"
            value={troops.troop6}
            className="text-white w-16 bg-calculatorInputBG p-1 border-mainAttack border-2"
            onChange={(e) => handleTroopChange("troop6", e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-1 place-items-center">
          <ScoutResponsive />
          <input
            type="number"
            value={troops.troop7}
            className="text-white w-16 bg-calculatorInputBG p-1 border-mainAttack border-2"
            onChange={(e) => handleTroopChange("troop7", e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-1 place-items-center">
          <CatapultResponsive />
          <input
            type="number"
            value={troops.troop8}
            className="text-white w-16 bg-calculatorInputBG p-1 border-mainAttack border-2"
            onChange={(e) => handleTroopChange("troop8", e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-1 place-items-center">
          <ConquererResponsive />
          <input
            type="number"
            value={troops.troop9}
            className="text-white w-16 bg-calculatorInputBG p-1 border-mainAttack border-2"
            onChange={(e) => handleTroopChange("troop9", e.target.value)}
          />
        </div>
      </div>
      <div className="flex flex-col gap-1 place-items-center">
        <div className="text-mainDarkBrown font-semibold">Bonus</div>
        <input
          type="number"
          value={bonus}
          onChange={handleBonusChange}
          className="bg-calculatorInputBG p-1 border-mainAttack border-2"
        />
      </div>
    </div>
  );
}
