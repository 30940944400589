import { useContext } from "react";
import { AuthContext } from "..";
import { LayoutSelector } from "./LayoutSelector";
import { Navigate, Outlet } from "react-router-dom";

export function GameRoute() {
  const { needsToJoin } = useContext(AuthContext);

  if (needsToJoin) {
    return <Navigate to="/round/join" replace />;
  }

  return <Outlet />;
}
