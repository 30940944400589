import { SmallWheatIcon } from "../../images/resources/SmallWheatIcon";
import { SmallWoodIcon } from "../../images/resources/SmallWoodIcon";
import { SmallStoneIcon } from "../../images/resources/SmallStoneIcon";
import { SmallIronIcon } from "../../images/resources/SmallIronIcon";
import { useTimerState } from "../contexts/timerContext";
import { useContext, useEffect, useState } from "react";
import { HelperFunctions } from "../../HelperFunctions";
import { CityConfigContext } from "../../index";
import { InstaBuildingCooldown } from "../InstaBuildingCooldown";
import { RULE_CONFIG } from "../../RULES";
import { CasinoCoinResponsive } from "../../images/CasinoCoinResponsive";
import { InstaBuildTokenButton } from "../InstaBuildTokenButton";
import { AllBuildingOptions } from "../AllBuildingOptions";
import { JadeIconResponsive } from "../../images/resources/JadeIconResponsive";

export function UpgradeBuildingReqCard({
  upgradedBuilding,
  upgradeBuilding,
  actualBuildTime,
  hasEnoughResources,
  isMaxLevel,
  building,
  bonuses,
  canInstaBuild,
}) {
  const allTimers = useTimerState();

  const { insta_builds_left, cityTokens } = useContext(CityConfigContext);
  const [buildingTimers, setBuildingTimers] = useState([
    ...allTimers.resourceTimers,
    ...allTimers.buildingTimers,
  ]);

  useEffect(() => {
    setBuildingTimers([
      ...allTimers.resourceTimers,
      ...allTimers.buildingTimers,
    ]);
  }, [allTimers]);

  return (
    <div className="flex justify-center text-center text-sm md:text-base">
      <div className="">
        {upgradedBuilding && (
          <div className="">
            <h4 className="text-base md:text-xl font-semibold mb-2">
              Upgrade to {upgradedBuilding.building_level}
            </h4>
            {building.building_name !== "Caligula's Palace" ? (
              <div>
                {building.building_name !== "Victory Monument" ? (
                  <div className=" mb-4">
                    <div className="grid grid-cols-4 gap-6 xxs:grid-cols-4  text-xxs xxs:text-xs  font-semibold">
                      <div className="flex">
                        <SmallWheatIcon />
                        <h4>
                          {HelperFunctions.turnNumberToNumberic(
                            upgradedBuilding.wheat
                          )}
                        </h4>
                      </div>
                      <div className="flex">
                        <SmallWoodIcon />
                        <h4>
                          {HelperFunctions.turnNumberToNumberic(
                            upgradedBuilding.wood
                          )}
                        </h4>
                      </div>
                      <div className="flex">
                        <SmallStoneIcon />
                        <h4>
                          {HelperFunctions.turnNumberToNumberic(
                            upgradedBuilding.stone
                          )}
                        </h4>
                      </div>
                      <div className="flex">
                        <SmallIronIcon />
                        <h4>
                          {HelperFunctions.turnNumberToNumberic(
                            upgradedBuilding.iron
                          )}
                        </h4>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex">
                    <JadeIconResponsive />
                    <h4>
                      {HelperFunctions.turnNumberToNumberic(
                        upgradedBuilding.wheat
                      )}
                    </h4>
                  </div>
                )}
              </div>
            ) : (
              <div className="mb-4">
                <div className="flex justify-center">
                  <CasinoCoinResponsive />
                  <h4>{upgradedBuilding.wheat}</h4>
                </div>
              </div>
            )}
            <h4 className="mb-4">
              {HelperFunctions.secondsToTimestamp(actualBuildTime)}
              {bonuses && bonuses.building_speed_bonus > 0 && (
                <div>({bonuses.building_speed_bonus}% bonus)</div>
              )}
            </h4>
          </div>
        )}
        {upgradedBuilding && (
          <AllBuildingOptions
            bonuses={bonuses}
            build={upgradeBuilding}
            buildingTimers={buildingTimers}
            hasEnoughResources={hasEnoughResources}
            buildingName={building.building_name}
            nextBuildingLevel={upgradedBuilding.building_level}
            canInstaBuild={canInstaBuild}
          />
        )}
      </div>
    </div>
  );
}
