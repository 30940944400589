import { useContext, useEffect, useState } from "react";
import { CityConfigContext } from "../../index.jsx";
import { Link, useNavigate } from "react-router-dom";
import { timerActions } from "../reducers/timerReducer";
import { useTimerDispatch } from "../contexts/timerContext";
import { ClubmanResponsive } from "../../images/troops/ClubmanResponsive";
import { SpearmanResponsive } from "../../images/troops/SpearmanResponsive";
import { AxemanResponsive } from "../../images/troops/AxemanResponsive";
import { FounderIconResponsive } from "../../images/troops/FounderIconResponsive";
import { GuardianResponsive } from "../../images/troops/GuardianResponsive.jsx";
import { WarlordResponsive } from "../../images/troops/WarlordResponsive.jsx";
import { ScoutResponsive } from "../../images/troops/ScoutResponsive.jsx";
import RaiderTokenIcon from "../../images/tokens/RaiderToken.png";
import { AttackOptions } from "../lists/AttackOptions.jsx";
import { CatapultTargetList } from "../lists/CatapultTargetList.jsx";
import { CatapultResponsive } from "../../images/troops/CatapultResponsive.jsx";
import { ConquererResponsive } from "../../images/troops/ConquererResponsive.jsx";
import { StyledUser } from "../StyledUser.jsx";
import { CONSTANTS } from "../../constants.js";

export function MapAttackCard(props) {
  const { troops, cityTokens, inCityId } = useContext(CityConfigContext);
  const dispatch = useTimerDispatch();
  const [hasCatapults, setHasCatapults] = useState(false);
  const [catapultTarget, setCatapultTarget] = useState(null);
  const [selectedAttackOption, setSelectedAttackOption] = useState("normal");
  const [scoutingSelected, setScoutingSelected] = useState(false);
  const [attackData, setAttackData] = useState({
    toCity: props.cityId,
    troops: {
      troop1: 0,
      troop2: 0,
      troop3: 0,
      troop4: 0,
      troop5: 0,
      troop6: 0,
      troop7: 0,
      troop8: 0,
      troop9: 0,
    },
  });

  useEffect(() => {
    if (troops) {
      troops.forEach((troopItem) => {
        if (troopItem.type === "Catapult") {
          if (troopItem.in_city_amount > 0) {
            setHasCatapults(true);
          }
        }
      });
    }
  }, [troops]);

  const [errorText, setErrorText] = useState();
  const [warningText, setWarningText] = useState();
  const navigate = useNavigate();
  const sendTroops = async (reinforce = false) => {
    // validation
    let hasEnoughTroops = true;
    troops.forEach((troop) => {
      if (
        troop.type === "Clubman" &&
        attackData.troops.troop1 > troop.in_city_amount
      ) {
        hasEnoughTroops = false;
      }
      if (
        troop.type === "Spearman" &&
        attackData.troops.troop2 > troop.in_city_amount
      ) {
        hasEnoughTroops = false;
      }
      if (
        troop.type === "Axeman" &&
        attackData.troops.troop3 > troop.in_city_amount
      ) {
        hasEnoughTroops = false;
      }
      if (
        troop.type === "Founder" &&
        attackData.troops.troop4 > troop.in_city_amount
      ) {
        hasEnoughTroops = false;
      }
      if (
        troop.type === "Guardian" &&
        attackData.troops.troop5 > troop.in_city_amount
      ) {
        hasEnoughTroops = false;
      }
      if (
        troop.type === "Warlord" &&
        attackData.troops.troop6 > troop.in_city_amount
      ) {
        hasEnoughTroops = false;
      }
      if (
        troop.type === "Scout" &&
        attackData.troops.troop7 > troop.in_city_amount
      ) {
        hasEnoughTroops = false;
      }
      if (
        troop.type === "Catapult" &&
        attackData.troops.troop8 > troop.in_city_amount
      ) {
        hasEnoughTroops = false;
      }
      if (
        troop.type === "Conquerer" &&
        attackData.troops.troop9 > troop.in_city_amount
      ) {
        hasEnoughTroops = false;
      }
    });

    if (!hasEnoughTroops) {
      setErrorText("You don't have enough troops");
      return;
    }
    if (selectedAttackOption === "raider" && cityTokens.raider_count <= 0) {
      setErrorText("You dont have any raider tokens.");
      return;
    }

    setErrorText("");
    if (
      attackData.troops.troop1 <= 0 &&
      attackData.troops.troop2 <= 0 &&
      attackData.troops.troop3 <= 0 &&
      attackData.troops.troop4 <= 0 &&
      attackData.troops.troop5 <= 0 &&
      attackData.troops.troop6 <= 0 &&
      attackData.troops.troop7 <= 0 &&
      attackData.troops.troop8 <= 0 &&
      attackData.troops.troop9 <= 0
    ) {
      setErrorText("You have to select atleast one troop");
      return;
    }

    if (selectedAttackOption === "scout" && attackData.troops.troop7 <= 0) {
      setErrorText("You need to select some scouts for this mission.");
      return;
    }

    let url;
    const baseUrl = `${import.meta.env.VITE_GAMEHOST}/api/troops/attack`;

    if (selectedAttackOption === "raider") {
      url = `${baseUrl}?ignore_troops=true`;
    } else if (
      selectedAttackOption === "scout" &&
      attackData.troops.troop7 > 0
    ) {
      attackData.troops = {
        ...attackData.troops,
        troop1: 0,
        troop2: 0,
        troop3: 0,
        troop4: 0,
        troop5: 0,
        troop6: 0,
        troop8: 0,
        troop9: 0,
      };
      url = `${baseUrl}?should_scout=true`;
    } else {
      url = baseUrl;
    }
    if (reinforce) {
      url += `${url.includes("?") ? "&" : "?"}reinforce=true`;
    }

    const reqBody = {
      catapultTarget: catapultTarget,
      attackData: attackData,
      cityId: inCityId,
    };
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(reqBody),
      credentials: "include",
    });

    if (res.status === 200) {
      const data = await res.json();
      if (reinforce) {
        dispatch({
          type: timerActions.ADD_TIMER,
          category: "outgoingReinforcementTimer",
          payload: {
            duration: data.travelTimeMillies,
            timeLeft: Math.ceil(data.timeLeft / 1000),
            endTime: data.arrivalTime,
            attackId: data.attackId,
            detailsId: data.attackDetailsId,
            attackerCityId: data.attackerCity,
            attackerCityName: data.attackerCityname,
            defenderCityName: data.defenderCityname,
            attackerUsername: data.attackerUsername,
            defenderCityId: data.defenderCity,
            defenderUsername: data.defenderUsername,
            troop1: data.troopAttackedWithAmount.troop1,
            troop2: data.troopAttackedWithAmount.troop2,
            troop3: data.troopAttackedWithAmount.troop3,
            troop4: data.troopAttackedWithAmount.troop4,
            troop5: data.troopAttackedWithAmount.troop5,
            troop6: data.troopAttackedWithAmount.troop6,
            troop7: data.troopAttackedWithAmount.troop7,
            troop8: data.troopAttackedWithAmount.troop8,
            troop9: data.troopAttackedWithAmount.troop9,
          },
        });
      } else {
        dispatch({
          type: timerActions.ADD_TIMER,
          category: "outgoingAttackTimer",
          payload: {
            duration: data.travelTimeMillies,
            timeLeft: Math.ceil(data.timeLeft / 1000),
            endTime: data.arrivalTime,
            attackId: data.attackId,
            detailsId: data.attackDetailsId,
            attackerCityId: data.attackerCity,
            attackerCityName: data.attackerCityname,
            defenderCityName: data.defenderCityname,
            attackerUsername: data.attackerUsername,
            defenderCityId: data.defenderCity,
            defenderUsername: data.defenderUsername,
            troop1: data.troopAttackedWithAmount.troop1,
            troop2: data.troopAttackedWithAmount.troop2,
            troop3: data.troopAttackedWithAmount.troop3,
            troop4: data.troopAttackedWithAmount.troop4,
            troop5: data.troopAttackedWithAmount.troop5,
            troop6: data.troopAttackedWithAmount.troop6,
            troop7: data.troopAttackedWithAmount.troop7,
            troop8: data.troopAttackedWithAmount.troop8,
            troop9: data.troopAttackedWithAmount.troop9,
          },
        });
      }

      navigate("/resources");
    }
    if (res.status === 401) {
      const data = await res.text();
      setErrorText(data);
    }
  };

  useEffect(() => {
    if (attackData.troops.troop9 > 0 && props.actionMode === "reinforce") {
      setWarningText(
        "Giving away your Conquerer will not let you make a new one!"
      );
    } else if (attackData.troops.troop9 > 0) {
      setWarningText(
        `This will occupy the city if it has no mapchamber, no surviving troops, and it is not the capital (Unless its ${CONSTANTS.CURRENT_ENDGAME_NPC})!`
      );
    } else {
      setWarningText(undefined);
    }
  }, [attackData]);

  return (
    <div className="text-white">
      <button
        className="bg-mainAttack  px-4 py-2 rounded shadow-md hover:text-purple-600 focus:outline-none mb-2"
        onClick={props.hideMenu}
      >
        Go back
      </button>
      <div className="font-semibold mb-2  flex items-center justify-center md:text-lg">
        {props.actionMode === "attack" ? "Attack" : "Help"} {props.targetCity} (
        <Link
          className="text-blue-600 font-semibold hover:underline"
          to={`/profile/${props.targetUser}`}
        >
          <StyledUser
            username={props.targetUser}
            nameColor={props.targetUserColor}
            nameIcon={props.targetUserIcon}
            nameTitle={props.targetUserTitle}
          />
        </Link>
        )
      </div>
      <div className="grid grid-cols-2 xxs:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 mb-2">
        {troops.map((troop) => (
          <>
            {troop.in_city_amount > 0 && (
              <div key={troop.type} className="text-sm">
                {troop.type === "Clubman" && (
                  <div className="">
                    <ClubmanResponsive />
                    <input
                      min={0}
                      max={troop.in_city_amount}
                      type="number"
                      className="w-12 bg-gray-800"
                      disabled={scoutingSelected}
                      value={attackData.troops.troop1}
                      onChange={(e) =>
                        setAttackData((prevState) => ({
                          ...prevState,
                          troops: {
                            ...prevState.troops,
                            troop1: Number(e.target.value),
                          },
                        }))
                      }
                    />
                    <span
                      className="text-blue-500 hover:text-blue-700"
                      onClick={() =>
                        setAttackData((prevState) => ({
                          ...prevState,
                          troops: {
                            ...prevState.troops,
                            troop1: troop.in_city_amount,
                          },
                        }))
                      }
                    >
                      ({troop.in_city_amount})
                    </span>
                  </div>
                )}

                {troop.type === "Spearman" && (
                  <div className="">
                    <SpearmanResponsive />
                    <input
                      min={0}
                      max={troop.in_city_amount}
                      type="number"
                      className="w-12 bg-gray-800"
                      disabled={scoutingSelected}
                      value={attackData.troops.troop2}
                      onChange={(e) =>
                        setAttackData((prevState) => ({
                          ...prevState,
                          troops: {
                            ...prevState.troops,
                            troop2: Number(e.target.value),
                          },
                        }))
                      }
                    />
                    <span
                      className="text-blue-500 hover:text-blue-700"
                      onClick={() =>
                        setAttackData((prevState) => ({
                          ...prevState,
                          troops: {
                            ...prevState.troops,
                            troop2: troop.in_city_amount,
                          },
                        }))
                      }
                    >
                      ({troop.in_city_amount})
                    </span>
                  </div>
                )}

                {troop.type === "Axeman" && (
                  <div className="">
                    <AxemanResponsive />
                    <input
                      min={0}
                      max={troop.in_city_amount}
                      type="number"
                      className="w-12 bg-gray-800"
                      value={attackData.troops.troop3}
                      disabled={scoutingSelected}
                      onChange={(e) =>
                        setAttackData((prevState) => ({
                          ...prevState,
                          troops: {
                            ...prevState.troops,
                            troop3: Number(e.target.value),
                          },
                        }))
                      }
                    />
                    <span
                      className="text-blue-500 hover:text-blue-700"
                      onClick={() =>
                        setAttackData((prevState) => ({
                          ...prevState,
                          troops: {
                            ...prevState.troops,
                            troop3: troop.in_city_amount,
                          },
                        }))
                      }
                    >
                      ({troop.in_city_amount})
                    </span>
                  </div>
                )}

                {troop.type === "Founder" && (
                  <div className="">
                    <FounderIconResponsive />
                    <input
                      min={0}
                      max={troop.in_city_amount}
                      type="number"
                      className="w-12 bg-gray-800"
                      value={attackData.troops.troop4}
                      disabled={scoutingSelected}
                      onChange={(e) =>
                        setAttackData((prevState) => ({
                          ...prevState,
                          troops: {
                            ...prevState.troops,
                            troop4: Number(e.target.value),
                          },
                        }))
                      }
                    />
                    <span
                      className="text-blue-500 hover:text-blue-700"
                      onClick={() =>
                        setAttackData((prevState) => ({
                          ...prevState,
                          troops: {
                            ...prevState.troops,
                            troop4: troop.in_city_amount,
                          },
                        }))
                      }
                    >
                      ( {troop.in_city_amount})
                    </span>
                  </div>
                )}
                {troop.type === "Guardian" && (
                  <div className="">
                    <GuardianResponsive />
                    <input
                      min={0}
                      max={troop.in_city_amount}
                      type="number"
                      className="w-12 bg-gray-800"
                      value={attackData.troops.troop5}
                      disabled={scoutingSelected}
                      onChange={(e) =>
                        setAttackData((prevState) => ({
                          ...prevState,
                          troops: {
                            ...prevState.troops,
                            troop5: Number(e.target.value),
                          },
                        }))
                      }
                    />
                    <span
                      className="text-blue-500 hover:text-blue-700"
                      onClick={() =>
                        setAttackData((prevState) => ({
                          ...prevState,
                          troops: {
                            ...prevState.troops,
                            troop5: troop.in_city_amount,
                          },
                        }))
                      }
                    >
                      ( {troop.in_city_amount})
                    </span>
                  </div>
                )}

                {troop.type === "Warlord" && (
                  <div className="">
                    <WarlordResponsive />
                    <input
                      min={0}
                      max={troop.in_city_amount}
                      type="number"
                      className="w-12 bg-gray-800"
                      value={attackData.troops.troop6}
                      disabled={scoutingSelected}
                      onChange={(e) =>
                        setAttackData((prevState) => ({
                          ...prevState,
                          troops: {
                            ...prevState.troops,
                            troop6: Number(e.target.value),
                          },
                        }))
                      }
                    />
                    <span
                      className="text-blue-500 hover:text-blue-700"
                      onClick={() =>
                        setAttackData((prevState) => ({
                          ...prevState,
                          troops: {
                            ...prevState.troops,
                            troop6: troop.in_city_amount,
                          },
                        }))
                      }
                    >
                      ( {troop.in_city_amount})
                    </span>
                  </div>
                )}
                {troop.type === "Scout" && (
                  <div className="">
                    <ScoutResponsive />
                    <input
                      min={0}
                      max={troop.in_city_amount}
                      type="number"
                      className="w-12 bg-gray-800"
                      value={attackData.troops.troop7}
                      onChange={(e) =>
                        setAttackData((prevState) => ({
                          ...prevState,
                          troops: {
                            ...prevState.troops,
                            troop7: Number(e.target.value),
                          },
                        }))
                      }
                    />
                    <span
                      className="text-blue-500 hover:text-blue-700"
                      onClick={() =>
                        setAttackData((prevState) => ({
                          ...prevState,
                          troops: {
                            ...prevState.troops,
                            troop7: troop.in_city_amount,
                          },
                        }))
                      }
                    >
                      ( {troop.in_city_amount})
                    </span>
                  </div>
                )}

                {troop.type === "Catapult" && (
                  <div className="">
                    <CatapultResponsive />
                    <input
                      min={0}
                      max={troop.in_city_amount}
                      type="number"
                      className="w-12 bg-gray-800"
                      value={attackData.troops.troop8}
                      disabled={scoutingSelected}
                      onChange={(e) =>
                        setAttackData((prevState) => ({
                          ...prevState,
                          troops: {
                            ...prevState.troops,
                            troop8: Number(e.target.value),
                          },
                        }))
                      }
                    />
                    <span
                      className="text-blue-500 hover:text-blue-700"
                      onClick={() =>
                        setAttackData((prevState) => ({
                          ...prevState,
                          troops: {
                            ...prevState.troops,
                            troop8: troop.in_city_amount,
                          },
                        }))
                      }
                    >
                      ( {troop.in_city_amount})
                    </span>
                  </div>
                )}

                {troop.type === "Conquerer" && (
                  <div className="">
                    <ConquererResponsive />
                    <input
                      min={0}
                      max={troop.in_city_amount}
                      type="number"
                      className="w-12 bg-gray-800"
                      value={attackData.troops.troop9}
                      disabled={scoutingSelected}
                      onChange={(e) =>
                        setAttackData((prevState) => ({
                          ...prevState,
                          troops: {
                            ...prevState.troops,
                            troop9: Number(e.target.value),
                          },
                        }))
                      }
                    />
                    <span
                      className="text-blue-500 hover:text-blue-700"
                      onClick={() =>
                        setAttackData((prevState) => ({
                          ...prevState,
                          troops: {
                            ...prevState.troops,
                            troop9: troop.in_city_amount,
                          },
                        }))
                      }
                    >
                      ( {troop.in_city_amount})
                    </span>
                  </div>
                )}
              </div>
            )}
          </>
        ))}
      </div>
      {props.actionMode === "reinforce" && (
        <>
          <button
            className="ml-4 bg-mainDefend px-4 py-2 rounded shadow-md hover:text-purple-600 focus:outline-none"
            onClick={() => sendTroops(true)}
          >
            Give troops
          </button>
        </>
      )}

      {props.actionMode === "attack" && (
        <>
          <div className="flex place-items-center">
            <button
              className="ml-4 bg-mainDefend px-4 py-2 rounded shadow-md hover:text-purple-600 focus:outline-none"
              onClick={() => sendTroops()}
            >
              Attack
            </button>

            {/* NEW ATTACK OPTIONS */}
            <div className="ml-4">
              <AttackOptions
                setSelectedAttackOption={setSelectedAttackOption}
                setScoutingSelected={setScoutingSelected}
                raiderTokenAmount={cityTokens?.raider_count || 0}
              />
            </div>

            {cityTokens?.raider_count > 0 && (
              <div className="flex ml-2 place-items-center">
                <div>{cityTokens.raider_count}/1</div>
                <img
                  src={RaiderTokenIcon}
                  alt="Raider token"
                  className="w-8 h-8 ml-2"
                />
              </div>
            )}

            {attackData.troops.troop8 > 0 &&
              hasCatapults &&
              !scoutingSelected && (
                <CatapultTargetList setCatapultTarget={setCatapultTarget} />
              )}
          </div>
        </>
      )}
      {warningText && <div className="text-yellow-400 mt-2">{warningText}</div>}
      {errorText && <div className="text-red-500 mt-2">{errorText}</div>}
    </div>
  );
}
