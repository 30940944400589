import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "..";

export function JoinRoundComponent() {
  const [selectedArea, setSelectedArea] = useState(null);
  const [status, setStatus] = useState("");
  const { setNeedsToJoin } = useContext(AuthContext);

  const navigate = useNavigate();

  async function joinRound() {
    if (!selectedArea) {
      setStatus("Please select a starting area");
      return;
    }

    const res = await fetch(
      `${import.meta.env.VITE_GAMEHOST}/api/auth/round/join`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          starting_area: selectedArea,
        }),
        credentials: "include",
      }
    );

    if (res.status === 200) {
      setNeedsToJoin(false);

      window.location.replace(`${import.meta.env.VITE_FRONTEND}/resources`);
    }
  }

  return (
    <div className="bg-gradient-to-r from-mainAttack2 to-mainAttack3 p-6 rounded-lg shadow-md max-w-md mx-auto">
      <h4 className="text-2xl font-semibold text-center text-white mb-4">
        Join New Season
      </h4>

      <div className="bg-mainReturn p-4 rounded-lg shadow-inner">
        <p className="text-gray-200 mb-4">
          New Siege season has kicked off, jump into action now!
        </p>
        <select
          className="bg-gray-800 text-white w-full p-3 rounded-lg mb-4 outline-none focus:ring-2 focus:ring-blue-500"
          name="Starting area"
          onChange={(e) => setSelectedArea(e.target.value)}
        >
          <option value="" disabled selected>
            Select your starting area!
          </option>
          <option value="Woods">Woods</option>
          <option value="Mountains">Mountains</option>
          <option value="Snowlands">Snowlands</option>
          <option value="Savanna">Savanna</option>
        </select>
        {selectedArea && (
          <p className="text-gray-300">
            Start out in <span className="font-semibold">{selectedArea}</span>
          </p>
        )}
      </div>

      {status && (
        <div className="mt-4 text-sm text-red-500 text-center">{status}</div>
      )}

      <button
        onClick={joinRound}
        className="mt-6 w-full bg-blue-600 hover:bg-blue-500 text-white font-semibold py-3 rounded-lg transition-all"
      >
        Join Round
      </button>
    </div>
  );
}
