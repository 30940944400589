import Jade from "./JadeIcon.png";

export function JadeIconResponsive() {
  return (
    <img
      className="h-[3vw] w-[3vw] max-h-[25px] max-w-[25px] min-h-[20px] min-w-[20px] inline"
      src={Jade}
      alt="Small jade icon"
    />
  );
}
