import { AllFixed } from "./Components/menus/AllFixed";
import { useContext, useEffect } from "react";
import { AuthContext, CityConfigContext, UserContext } from "./index";
import { NewLootChestModal } from "./Components/modals/NewLootChestModal";
import { useTimerDispatch } from "./Components/contexts/timerContext";
import { timerActions } from "./Components/reducers/timerReducer";
import { useLocation } from "react-router-dom";
import { OccupyCityComponent } from "./Components/OccupyCityComponent";

export function FixedLayout({ children }) {
  const location = useLocation();
  const path = location.pathname;
  const shouldShowFixedLayout =
    !path.endsWith("/about") &&
    !path.endsWith("/discord-login") &&
    !path.endsWith("/login") &&
    !path.endsWith("/register") &&
    !path.endsWith("/gameOver");
  const { authToken } = useContext(AuthContext);
  const { socketState, cityListState, gameOver } = useContext(UserContext);
  if (gameOver) {
    return null;
  }
  const { showNewLootChestModal, inCityId, inOccupiedCity, setInOccupiedCity } =
    useContext(CityConfigContext);
  const dispatch = useTimerDispatch();

  useEffect(() => {
    if (socketState) {
      socketState.on("update_foundry_queue", (data) => {
        dispatch({
          type: timerActions.CLEAR_FOUNDRY,
        });
        const now = new Date().getTime();
        data.sort((a, b) => a.timeLeft - b.timeLeft);

        data.forEach((item) => {
          if (item.timeLeft > 0) {
            dispatch({
              type: timerActions.ADD_TIMER,
              category: "foundryTimers",
              payload: {
                duration: item.totalTime,
                timeLeft: item.timeLeft,
                endTime: item.end_time,
                troopType: item.troopType,
                amount: item.amount,
                state: item.state,
                time_per_unit: item.time_per_unit,
                timeLeftNextUnit: item.time_left_next_unit,
              },
            });
          }
        });
      });

      socketState.on("update_mapchamber_queue", (data) => {
        dispatch({
          type: timerActions.CLEAR_MAPCHAMBER,
        });

        const now = new Date().getTime();
        data.sort((a, b) => a.timeLeft - b.timeLeft);
        data.forEach((item) => {
          const newTimeLeft = Math.floor((item.end_time - now) / 1000);
          item.timeLeft = newTimeLeft;
          if (newTimeLeft > 0) {
            const timePassed = now - item.start_time;
            const remainingTimeForNextUnit = Math.ceil(
              (item.time_per_unit - (timePassed % item.time_per_unit)) / 1000
            );
            dispatch({
              type: timerActions.ADD_TIMER,
              category: "trainFoundersTimers",
              payload: {
                duration: item.totalTime,
                timeLeft: item.timeLeft,
                endTime: item.end_time,
                troopType: item.troopType,
                amount: item.amount,
                state: item.state,
                time_per_unit: item.time_per_unit,
                timeLeftNextUnit: remainingTimeForNextUnit,
              },
            });
          }
        });
      });

      socketState.on("update_barracks_queue", (data) => {
        // stop all barracks timers
        // Get new ones in this request to replace
        // WTF AM I THINKING WITH LMAO
        dispatch({
          type: timerActions.CLEAR_BARRACKS,
        });

        const now = new Date().getTime();
        data.sort((a, b) => a.timeLeft - b.timeLeft);
        data.forEach((item) => {
          const newTimeLeft = Math.floor((item.end_time - now) / 1000);
          item.timeLeft = newTimeLeft;
          if (newTimeLeft > 0) {
            const timePassed = now - item.start_time;
            const remainingTimeForNextUnit = Math.ceil(
              (item.time_per_unit - (timePassed % item.time_per_unit)) / 1000
            );

            dispatch({
              type: timerActions.ADD_TIMER,
              category: "trainTroopsTimers",
              payload: {
                duration: item.totalTime,
                timeLeft: item.timeLeft,
                endTime: item.end_time,
                troopType: item.troopType,
                amount: item.amount,
                state: item.state,
                time_per_unit: item.time_per_unit,
                timeLeftNextUnit: remainingTimeForNextUnit,
              },
            });
          }
        });
      });
      socketState.on("marketplace_retrieval_timer", (data) => {
        // Should dispatch the socket retrieval timers to the queue when traders has arrived etc
        dispatch({
          type: timerActions.ADD_TIMER,
          category: "retrieveTradersTimers",
          payload: {
            duration: data.travelTimeMillies,
            timeLeft: data.timeLeft,
            endTime: data.arrival_time,
            queueId: data.itemQueueId,
            extra: {
              toCity: data.toCity,
              toCityName: data.toCityName,
              fromCity: data.fromCity,
              fromCityName: data.fromCityName,
              toUserName: data.toUserName,
              fromUserName: data.fromUserName,
              wheat: data.wheat,
              wood: data.wood,
              stone: data.stone,
              iron: data.iron,
              jade: data.jade,
              tradersNeeded: data.tradersNeeded,
            },
          },
        });
      });

      socketState.on("marketplace_incoming_timer", (data) => {
        // Add this to the timeractions with a new category
        dispatch({
          type: timerActions.ADD_TIMER,
          category: "incomingResourceTimers",
          payload: {
            duration: data.travelTimeMillies,
            timeLeft: data.timeLeft,
            endTime: data.arrival_time,
            queueId: data.itemQueueId,
            extra: {
              toCity: data.toCity,
              toCityName: data.toCityName,
              fromCity: data.fromCity,
              fromCityName: data.fromCityName,
              toUserName: data.toUserName,
              fromUserName: data.fromUserName,
              wheat: data.wheat,
              wood: data.wood,
              stone: data.stone,
              iron: data.iron,
              jade: data.jade,
              tradersNeeded: data.tradersNeeded,
            },
          },
        });
      });

      socketState.on("marketplace_outgoing_timer", (data) => {
        dispatch({
          type: timerActions.ADD_TIMER,
          category: "sendResourcesTimers",
          payload: {
            duration: data.travelTimeMillies,
            timeLeft: data.timeLeft,
            endTime: data.arrival_time,
            queueId: data.itemQueueId,
            extra: {
              toCity: data.toCity,
              toCityName: data.toCityName,
              fromCity: data.fromCity,
              fromCityName: data.fromCityName,
              toUserName: data.toUserName,
              fromUserName: data.fromUserName,
              wheat: data.wheat,
              wood: data.wood,
              stone: data.stone,
              iron: data.iron,
              jade: data.jade,
              tradersNeeded: data.tradersNeeded,
            },
          },
        });
      });

      socketState.on("reinforcement_incoming", (data) => {
        dispatch({
          type: timerActions.ADD_TIMER,
          category: "incomingReinforcementTimer",
          payload: {
            duration: data.travelTimeMillies,
            timeLeft: Math.ceil(data.timeLeft / 1000),
            endTime: data.arrivalTime,
            attackId: data.attackId,
            detailsId: data.attackDetailsId,
            attackerCityId: data.attackerCity,
            defenderCityId: data.defenderCity,
            attackerCityName: data.attackerCityname,
            defenderCityName: data.defenderCityname,
            attackerUsername: data.attackerUsername,
            defenderUsername: data.defenderUsername,
            troop1: data.troopAttackedWithAmount.troop1,
            troop2: data.troopAttackedWithAmount.troop2,
            troop3: data.troopAttackedWithAmount.troop3,
            troop4: data.troopAttackedWithAmount.troop4,
            troop5: data.troopAttackedWithAmount.troop5,
            troop6: data.troopAttackedWithAmount.troop6,
            troop7: data.troopAttackedWithAmount.troop7,
            troop8: data.troopAttackedWithAmount.troop8,
          },
        });
      });

      socketState.on("attack_incoming", (data) => {
        dispatch({
          type: timerActions.ADD_TIMER,
          category: "incomingAttackTimer",
          payload: {
            duration: data.travelTimeMillies,
            timeLeft: Math.ceil(data.timeLeft / 1000),
            endTime: data.arrivalTime,
            attackId: data.attackId,
            detailsId: data.attackDetailsId,
            attackerCityId: data.attackerCity,
            attackerUsername: data.attackerUsername,
            defenderCityId: data.defenderCity,
            defenderUsername: data.defenderUsername,
            attackerCityName: data.attackerCityname,
            defenderCityName: data.defenderCityname,
            troop1: "?????",
            troop2: "?????",
            troop3: "?????",
            troop4: "?????",
            troop5: "?????",
            troop6: "?????",
            troop7: "?????",
            troop8: "?????",
          },
        });
      });

      socketState.on("attack_returning", (data) => {
        dispatch({
          type: timerActions.ADD_TIMER,
          category: "returningAttackTimer",
          payload: {
            duration: data.travelTimeMillies,
            timeLeft: data.timeLeft,
            endTime: data.arrival_time,
            retrievalId: data.retrievalId,
            fromCityName: data.fromCityName,
            toCityName: data.toCityName,
            fromUsername: data.fromUsername,
            toUsername: data.toUsername,
            troop1: data.troop1,
            troop2: data.troop2,
            troop3: data.troop3,
            troop4: data.troop4,
            wheat: data.wheat,
            wood: data.wood,
            stone: data.stone,
            iron: data.iron,
            jade: data.jade,
          },
        });
      });
      socketState.on("update_building_queueV2", async (data) => {
        // clear existing queue

        await addBuildingTimerToQueue(data, dispatch);
      });
    }
  }, [socketState]);

  useEffect(() => {
    if (inCityId) {
      const fetchData = async () => {
        dispatch({
          type: timerActions.CLEAR_ALL,
        });
        await getBuildingTimers(dispatch, inCityId);
        await getBarracksTimers(dispatch, inCityId);
        await getFounderTrainingTimer(dispatch, inCityId);
        await getFoundryTimers(dispatch, inCityId);
        await getMarketplaceTimers(dispatch, inCityId);
        await getAllAttackTimers(dispatch, inCityId);
        await getAllNewCityTimers(dispatch, inCityId);
      };
      fetchData();
    }
  }, [inCityId]);

  useEffect(() => {
    if (setInOccupiedCity) {
      setInOccupiedCity(false);
      if (cityListState.length > 0 && inCityId) {
        cityListState.forEach((item) => {
          if (item.isOccupied && item.cityid === inCityId) {
            setInOccupiedCity(true);
          }
        });
      }
    }
  }, [cityListState, inCityId]);

  return (
    <>
      {authToken && shouldShowFixedLayout ? (
        <>
          <AllFixed />
          {showNewLootChestModal && <NewLootChestModal />}
          {inOccupiedCity ? <OccupyCityComponent /> : <>{children}</>}
        </>
      ) : (
        <>{children}</>
      )}
    </>
  );
}

async function addBuildingTimerToQueue(data, dispatch) {
  dispatch({
    type: timerActions.CLEAR_BUILDINGS,
  });
  const now = new Date().getTime();
  data.sort((a, b) => a.timeLeft - b.timeLeft);
  data.forEach((item) => {
    const newTimeLeft = Math.floor((item.end_time - now) / 1000);
    item.timeLeft = newTimeLeft;

    if (newTimeLeft > 0) {
      if (
        item.building_name === "Wheat" ||
        item.building_name === "Wood" ||
        item.building_name === "Stone" ||
        item.building_name === "Iron"
      ) {
        dispatch({
          type: timerActions.ADD_TIMER,
          category: "resourceTimers",
          payload: {
            duration: item.building_total_time,
            location: item.location,
            timeLeft: item.timeLeft,
            endTime: item.end_time,
            extra: {
              building_name: item.building_name,
              next_level: item.building_level,
            },
          },
        });
      } else {
        dispatch({
          type: timerActions.ADD_TIMER,
          category: "buildingTimers",
          payload: {
            duration: item.building_total_time,
            location: item.location,
            timeLeft: item.timeLeft,
            endTime: item.end_time,
            cityId: item.cityId,
            extra: {
              building_name: item.building_name,
              next_level: item.building_level,
            },
          },
        });
      }
    }
  });
}

async function getBuildingTimers(dispatch, inCityId) {
  const res = await fetch(
    `${import.meta.env.VITE_GAMEHOST}/api/building/buildingQueue/${inCityId}`,
    { credentials: "include" }
  );

  if (res.ok) {
    const data = await res.json();
    await addBuildingTimerToQueue(data, dispatch);
  }
}

async function getBarracksTimers(dispatch, inCityId) {
  const res = await fetch(
    `${import.meta.env.VITE_GAMEHOST}/api/troops/buildingqueue/${inCityId}`,
    { credentials: "include" }
  );
  if (!res.ok) {
    return;
  }

  const data = await res.json();

  const now = new Date().getTime();
  data.sort((a, b) => a.timeLeft - b.timeLeft);

  data.forEach((item) => {
    const newTimeLeft = Math.floor((item.end_time - now) / 1000);
    item.timeLeft = newTimeLeft;

    if (newTimeLeft > 0) {
      const timePassed = now - item.start_time;
      const remainingTimeForNextUnit = Math.ceil(
        (item.time_per_unit - (timePassed % item.time_per_unit)) / 1000
      );

      dispatch({
        type: timerActions.ADD_TIMER,
        category: "trainTroopsTimers",
        payload: {
          duration: item.totalTime,
          timeLeft: item.timeLeft,
          endTime: item.end_time,
          troopType: item.troopType,
          amount: item.amount,
          state: item.state,
          time_per_unit: item.time_per_unit,
          timeLeftNextUnit: remainingTimeForNextUnit,
        },
      });
    }
  });
}

async function getFoundryTimers(dispatch, inCityId) {
  const res = await fetch(
    `${import.meta.env.VITE_GAMEHOST}/api/foundry/buildingqueue/${inCityId}`,
    { credentials: "include" }
  );

  if (!res.ok) {
    return;
  }
  const data = await res.json();

  const now = new Date().getTime();

  data.forEach((item) => {
    if (item.timeLeft > 0) {
      dispatch({
        type: timerActions.ADD_TIMER,
        category: "foundryTimers",
        payload: {
          duration: item.totalTime,
          timeLeft: item.timeLeft,
          endTime: item.end_time,
          troopType: item.troopType,
          amount: item.amount,
          state: item.state,
          time_per_unit: item.time_per_unit,
          timeLeftNextUnit: item.time_left_next_unit,
        },
      });
    }
  });
}

async function getFounderTrainingTimer(dispatch, inCityId) {
  const res = await fetch(
    `${import.meta.env.VITE_GAMEHOST}/api/founder/buildingqueue/${inCityId}`,
    { credentials: "include" }
  );

  if (!res.ok) {
    return;
  }

  let data = await res.json();

  const now = new Date().getTime();

  data.forEach((item) => {
    const newTimeLeft = Math.floor((item.end_time - now) / 1000);
    item.timeLeft = newTimeLeft;

    if (newTimeLeft > 0) {
      const timePassed = now - item.start_time;
      const remainingTimeForNextUnit = Math.ceil(
        (item.time_per_unit - (timePassed % item.time_per_unit)) / 1000
      );
      dispatch({
        type: timerActions.ADD_TIMER,
        category: "trainFoundersTimers",
        payload: {
          duration: item.totalTime,
          timeLeft: item.timeLeft,
          endTime: item.end_time,
          troopType: item.troopType,
          amount: item.amount,
          state: item.state,
          time_per_unit: item.time_per_unit,
          timeLeftNextUnit: remainingTimeForNextUnit,
        },
      });
    }
  });
}

async function getMarketplaceTimers(dispatch, inCityId) {
  const res = await fetch(
    `${
      import.meta.env.VITE_GAMEHOST
    }/api/marketplace/buildingqueue/${inCityId}`,
    { credentials: "include" }
  );

  if (res.status === 200) {
    const data = await res.json();

    // data.outgoing has both incoming and outgoing traders
    // data.returning has the returning traders
    // loop over the data.outgoing and send them to the correct queue

    // loop over data.returning and add it to the returning traders queue
    data.returning.forEach((item) => {
      dispatch({
        type: timerActions.ADD_TIMER,
        category: "retrieveTradersTimers",
        payload: {
          duration: item.travelTimeMillies,
          timeLeft: item.timeLeft,
          endTime: item.arrival_time,
          queueId: item.itemQueueId,
          extra: {
            toCity: item.toCity,
            toCityName: item.toCityName,
            fromCity: item.fromCity,
            fromCityName: item.fromCityName,
            toUserName: item.toUserName,
            fromUserName: item.fromUserName,
            wheat: item.wheat,
            wood: item.wood,
            stone: item.stone,
            iron: item.iron,
            jade: item.jade,
            tradersNeeded: item.tradersNeeded,
          },
        },
      });
    });

    data.outgoing.forEach((item) => {
      if (item.toCity === inCityId) {
        // This city has incoming resources
        dispatch({
          type: timerActions.ADD_TIMER,
          category: "incomingResourceTimers",
          payload: {
            duration: item.travelTimeMillies,
            timeLeft: item.timeLeft,
            endTime: item.arrival_time,
            queueId: item.itemQueueId,
            extra: {
              toCity: item.toCity,
              toCityName: item.toCityName,
              fromCity: item.fromCity,
              fromCityName: item.fromCityName,
              toUserName: item.toUserName,
              fromUserName: item.fromUserName,
              wheat: item.wheat,
              wood: item.wood,
              stone: item.stone,
              iron: item.iron,
              jade: item.jade,
              tradersNeeded: item.tradersNeeded,
            },
          },
        });
      } else if (item.fromCity === inCityId) {
        // This city has outgoing resources
        dispatch({
          type: timerActions.ADD_TIMER,
          category: "sendResourcesTimers",
          payload: {
            duration: item.travelTimeMillies,
            timeLeft: item.timeLeft,
            endTime: item.arrival_time,
            queueId: item.itemQueueId,
            extra: {
              toCity: item.toCity,
              toCityName: item.toCityName,
              fromCity: item.fromCity,
              fromCityName: item.fromCityName,
              toUserName: item.toUserName,
              fromUserName: item.fromUserName,
              wheat: item.wheat,
              wood: item.wood,
              stone: item.stone,
              iron: item.iron,
              jade: item.jade,
              tradersNeeded: item.tradersNeeded,
            },
          },
        });
      }
    });
  }
}

async function getAllAttackTimers(dispatch, inCityId) {
  const res = await fetch(
    `${import.meta.env.VITE_GAMEHOST}/api/troops/attackQueue/${inCityId}`,
    { credentials: "include" }
  );

  if (res.status === 200) {
    const data = await res.json();
    const now = new Date().getTime();

    data.allReturningTroops.forEach((item) => {
      const timeLeftMillies = item.arrivalTime - now;
      const timeLeftSeconds = Math.ceil(timeLeftMillies / 1000);
      dispatch({
        type: timerActions.ADD_TIMER,
        category: "returningAttackTimer",
        payload: {
          duration: timeLeftMillies,
          timeLeft: timeLeftSeconds,
          endTime: item.arrivalTime,
          retrievalId: item.retrievalId,
          fromCityName: item.fromCityName,
          toCityName: item.toCityName,
          fromUsername: item.fromUsername,
          toUsername: item.toUsername,
          troop1: item.troop1,
          troop2: item.troop2,
          troop3: item.troop3,
          troop4: item.troop4,
          troop5: item.troop5,
          troop6: item.troop6,
          wheat: item.resource1,
          wood: item.resource2,
          stone: item.resource3,
          iron: item.resource4,
          jade: item.resource5,
        },
      });
    });
    data.incomingReinforcements.forEach((item) => {
      dispatch({
        type: timerActions.ADD_TIMER,
        category: "incomingReinforcementTimer",
        payload: {
          duration: item.travelTimeMillies,
          timeLeft: Math.ceil(item.timeLeft / 1000),
          endTime: item.arrivalTime,
          attackId: item.attackId,
          detailsId: item.attackDetailsId,
          attackerCityId: item.attackerCity,
          defenderCityId: item.defenderCity,
          attackerCityName: item.attackerCityname,
          defenderCityName: item.defenderCityname,
          attackerUsername: item.attackerUsername,
          defenderUsername: item.defenderUsername,
          troop1: item.troopAttackedWithAmount.troop1,
          troop2: item.troopAttackedWithAmount.troop2,
          troop3: item.troopAttackedWithAmount.troop3,
          troop4: item.troopAttackedWithAmount.troop4,
          troop5: item.troopAttackedWithAmount.troop5,
          troop6: item.troopAttackedWithAmount.troop6,
          troop7: item.troopAttackedWithAmount.troop7,
          troop8: item.troopAttackedWithAmount.troop8,
        },
      });
    });

    data.outgoingReinforcement.forEach((item) => {
      dispatch({
        type: timerActions.ADD_TIMER,
        category: "outgoingReinforcementTimer",
        payload: {
          duration: item.travelTimeMillies,
          timeLeft: Math.ceil(item.timeLeft / 1000),
          endTime: item.arrivalTime,
          attackId: item.attackId,
          detailsId: item.attackDetailsId,
          attackerCityId: item.attackerCity,
          attackerCityName: item.attackerCityname,
          defenderCityName: item.defenderCityname,
          attackerUsername: item.attackerUsername,
          defenderCityId: item.defenderCity,
          defenderUsername: item.defenderUsername,
          troop1: item.troopAttackedWithAmount.troop1,
          troop2: item.troopAttackedWithAmount.troop2,
          troop3: item.troopAttackedWithAmount.troop3,
          troop4: item.troopAttackedWithAmount.troop4,
          troop5: item.troopAttackedWithAmount.troop5,
          troop6: item.troopAttackedWithAmount.troop6,
          troop7: item.troopAttackedWithAmount.troop7,
          troop8: item.troopAttackedWithAmount.troop8,
        },
      });
    });
    data.incomingAttacks.forEach((item) => {
      const timeLeftMillies = item.arrivalTime - now;
      const timeLeftSeconds = Math.ceil(timeLeftMillies / 1000);
      dispatch({
        type: timerActions.ADD_TIMER,
        category: "incomingAttackTimer",
        payload: {
          duration: timeLeftSeconds,
          timeLeft: timeLeftSeconds,
          endTime: item.arrivalTime,
          attackId: item.attackId,
          detailsId: item.attackDetailsId,
          attackerCityId: item.attackerCity,
          attackerUsername: item.attackerUsername,
          defenderCityId: item.defenderCity,
          defenderUsername: item.defenderUsername,
          attackerCityName: item.attackerCityname,
          defenderCityName: item.defenderCityname,
          troop1: "?????",
          troop2: "?????",
          troop3: "?????",
          troop4: "?????",
          troop5: "?????",
          troop6: "?????",
        },
      });
    });

    data.outGoingAttacks.forEach((item) => {
      const timeLeftMillies = item.arrivalTime - now;
      const timeLeftSeconds = Math.ceil(timeLeftMillies / 1000);

      dispatch({
        type: timerActions.ADD_TIMER,
        category: "outgoingAttackTimer",
        payload: {
          duration: timeLeftMillies,
          timeLeft: timeLeftSeconds,
          endTime: item.arrivalTime,
          attackId: item.attackId,
          detailsId: item.attackDetailsId,
          attackerCityId: item.attackerCity,
          attackerCityName: item.attackerCityname,
          defenderCityName: item.defenderCityname,
          attackerUsername: item.attackerUsername,
          defenderCityId: item.defenderCity,
          defenderUsername: item.defenderUsername,
          troop1: item.troopAttackedWithAmount.troop1,
          troop2: item.troopAttackedWithAmount.troop2,
          troop3: item.troopAttackedWithAmount.troop3,
          troop4: item.troopAttackedWithAmount.troop4,
          troop5: item.troopAttackedWithAmount.troop5,
          troop6: item.troopAttackedWithAmount.troop6,
        },
      });
    });
  }
}

async function getAllNewCityTimers(dispatch, inCityId) {
  const res = await fetch(
    `${import.meta.env.VITE_GAMEHOST}/api/founder/newCityQueue/${inCityId}`,
    { credentials: "include" }
  );

  if (res.status === 200) {
    const data = await res.json();
    const now = new Date().getTime();
    data.forEach((item) => {
      const milliesTravelTime = item.endtime - now;
      const secondsTravelTime = Math.ceil(milliesTravelTime / 1000);
      dispatch({
        type: timerActions.ADD_TIMER,
        category: "foundNewCityTimer",
        payload: {
          duration: milliesTravelTime,
          timeLeft: secondsTravelTime,
          endTime: item.endtime,
          queueId: item.id,
          cityName: item.city_name,
        },
      });
    });
  }
}
