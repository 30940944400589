import { useContext, useEffect, useState } from "react";
import { AuthContext, UserContext } from "..";
import { SignedOutsNavBar } from "../Components/bars/SignedOutsNavBar";
import { SignedOutFooter } from "../Components/bars/SignedOutFooter";
import { FixedLayout } from "../FixedLayout";
import { GameOverHeader } from "../Components/bars/GameOverHeader";

export function GameUpdatesPage() {
  const { authToken } = useContext(AuthContext);
  const { gameOver } = useContext(UserContext);
  const [gameUpdates, setGameUpdates] = useState();

  const [error, setError] = useState();
  async function getGameUpdates() {
    const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/news/all`, {
      credentials: "include",
    });
    if (res.ok) {
      const data = await res.json();
      setGameUpdates(data);
    } else {
      setError("lame");
    }
  }

  useEffect(() => {
    getGameUpdates();
  }, []);
  if (!gameUpdates) {
    return <div>Loading...</div>;
  }

  return (
    <div className="w-screen h-screen bg-gradient-to-b from-mainLightGreen to-[#d4a373] flex flex-col ">
      {!authToken ? (
        <SignedOutsNavBar />
      ) : gameOver ? (
        <GameOverHeader />
      ) : (
        <FixedLayout />
      )}
      <div className="relative  top-[10vh]">
        <div className="mx-4 md:mx-0">
          <div className="md:w-[50vw] mx-auto bg-mainDarkBrown p-4  ">
            <div className="bg-mainLightBrown1 flex flex-col items-center  mb-auto my-auto px-4 py-8 max-h-[70vh] overflow-y-auto">
              <h4 className="text-4xl font-bold mb-8 text-white">
                Game Updates
              </h4>
              <div className="bg-[#dfd2b2] bg-opacity-90 p-6 rounded-lg shadow-lg w-full max-w-3xl">
                {gameUpdates.map((update, index) => (
                  <div
                    key={index}
                    className="border-b last:border-none border-gray-300 pb-4 mb-4 last:mb-0"
                  >
                    <h5 className="text-xl font-semibold text-gray-800">
                      {update.title}
                    </h5>
                    <p className="text-sm text-gray-500 mt-1">
                      {new Date(update.date).toISOString().split("T")[0]}
                    </p>
                    <p className="text-sm text-gray-500 mt-1">
                      {update.description}
                    </p>
                  </div>
                ))}
                {error && <h4 className="text-red-500">{error}</h4>}
              </div>
            </div>
          </div>
        </div>
      </div>
      {!authToken && <SignedOutFooter />}
    </div>
  );
}
