import { useContext, useEffect, useState } from "react";
import { HelperFunctions } from "../../HelperFunctions";
import { WarehouseContext } from "../..";
import { JadeIconResponsive } from "../../images/resources/JadeIconResponsive";

export function EndGameInfoModal({ roundEnd }) {
  const [timeLeft, setTimeLeft] = useState(0);
  const { warehouse } = useContext(WarehouseContext);

  useEffect(() => {
    const now = new Date();
    const roundEndDate = new Date(roundEnd);
    const timeLeft = new Date(roundEndDate.getTime() - now.getTime());

    setTimeLeft(Math.max(0, timeLeft));
  }, [roundEnd]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (timeLeft > 0) {
        setTimeLeft((prev) => prev - 1000);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [timeLeft]);

  return (
    <div className="absolute right-6 mt-12 w-48 bg-white border border-gray-200 rounded shadow-lg z-[10]">
      <ul className="p-2 space-y-2 text-gray-700">
        <li className="text-sm text-gray-700">
          New tribe has spawned, be aware
        </li>

        <li className="text-sm text-gray-700">Round will end {roundEnd}</li>
        <li className="text-sm ">
          Time left {HelperFunctions.formatTime(timeLeft)}
        </li>
        <li className="flex">
          <JadeIconResponsive />
          <div className="ml-1 text-sm">{warehouse.jade}</div>
        </li>
      </ul>
    </div>
  );
}
