import { useEffect, useState } from "react";
import { BadgeCard } from "../cards/BadgeCard";
import { SelectNameColorComponent } from "../SelectNameColorComponent";
import { SelectNameIconComponent } from "../SelectNameIconComponent";
import { SelectNameTitleComponent } from "../SelectNameTitleComponent";

export const EditProfileModal = ({
  description,
  setDescription,
  closeModal,
  updateDescription,
  setSelectedBadges,
  selectedBadges,
  allBadges,
  visibleBadges,
  selectedNameColor,
  setSelectedNameIcon,
  selectedNameIcon,
  setSelectedNameTitle,
  selectedNameTitle,
  setSelectedNameColor,
  availableColors,
  availableIcons,
  availableTitles,
  username,
}) => {
  const [status, setStatus] = useState("");

  const handleBadgeClick = (clickedBadge) => {
    setStatus("");
    setSelectedBadges((prevSelectedBadges) => {
      if (prevSelectedBadges.some((badge) => badge === clickedBadge)) {
        // If the badge was already selected, unselect it
        return prevSelectedBadges.filter((badge) => badge !== clickedBadge);
      } else if (prevSelectedBadges.length < 5) {
        // If there are less than 5 badges already selected, select the badge
        return [...prevSelectedBadges, clickedBadge];
      } else {
        // If the user already has 5 badges selected, return the previously selected badges without any changes
        setStatus("You can only display 5 badges at once!");
        return prevSelectedBadges;
      }
    });
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  useEffect(() => {
    const oldBadgeIds = [];
    visibleBadges.forEach((badge) => {
      oldBadgeIds.push(badge.badge_id);
    });
    setSelectedBadges(oldBadgeIds);
  }, [visibleBadges]);

  return (
    <div className="fixed z-10 inset-0 flex items-center justify-center  ">
      <div className="flex justify-center pt-4 px-4 pb-20 text-center ">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          onClick={closeModal}
        >
          {" "}
        </div>

        <div className="inline-block bg-mainLightYellow p-2 rounded-lg text-left overflow-hidden shadow-xl transform transition-all  mt-24 ">
          <div className="px-4 pb-4 sm:p-6 sm:pb-4 ">
            <h2 className="text-center bold text-2xl">Edit profile</h2>
            <div className="overflow-y-scroll max-h-[60vh]">
              <div className="bg-mainLightBrown3 p-2 w-full mb-4">
                <div className="border-b-2 pb-2">
                  <SelectNameTitleComponent
                    selectedNameTitle={selectedNameTitle}
                    setSelectedNameTitle={setSelectedNameTitle}
                    availableTitles={availableTitles}
                  />
                </div>
                <div className="border-b-2 pb-2">
                  <SelectNameIconComponent
                    selectedNameIcon={selectedNameIcon}
                    setSelectedNameIcon={setSelectedNameIcon}
                    availableIcons={availableIcons}
                    username={username}
                    selectedNameColor={selectedNameColor}
                  />
                </div>

                <div className="border-b-2 pb-2">
                  <SelectNameColorComponent
                    selectedNameColor={selectedNameColor}
                    setSelectedNameColor={setSelectedNameColor}
                    availableColors={availableColors}
                    username={username}
                  />
                </div>
              </div>

              <div className="text-center sm:mt-0  sm:text-left mb-4">
                <h3 className="text-lg leading-6 font-medium text-gray-900  text-center">
                  Edit Description
                </h3>
                <div className="mt-2 ">
                  <textarea
                    value={description}
                    onChange={handleDescriptionChange}
                    className="w-full h-20  text-gray-50 focus:outline-none focus:border-blue-50 bg-mainLightBrown3 p-2"
                  />
                </div>
              </div>
              {allBadges.length > 0 && (
                <div>
                  <h4 className="text-base md:text-lg font-semibold text-center">
                    Selected: {selectedBadges.length}/5
                  </h4>
                  <div className="bg-mainLightBrown3 grid grid-cols-2 xs:grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-7 xl:grid-cols-8 overflow-y-scroll max-h-[25vh]">
                    {allBadges.map((badge, index) => (
                      <div key={index}>
                        <BadgeCard
                          badge={badge}
                          handleBadgeClick={handleBadgeClick}
                          isSelected={selectedBadges.some(
                            (selectedBadge) => selectedBadge === badge.badge_id
                          )}
                          isClickable={true}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div>{status && <h4 className="text-red-400">{status}</h4>}</div>
          <div className="px-4 py-3 flex justify-center items-center">
            <button
              type="button"
              className="ml-4 w-[50%] inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={closeModal}
            >
              Cancel
            </button>
            <button
              type="button"
              className="w-[40%] inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-mainDarkBrown text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={updateDescription}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
