import BuilderStage1Badge from "../../images/badges/achievementIcons/resourcesSpendtOnStructures/CardboardResourcesSpendtOnstructures.png";
import BuilderStage2Badge from "../../images/badges/achievementIcons/resourcesSpendtOnStructures/WoodResourcesSpendtOnStructures.png";
import BuilderStage3Badge from "../../images/badges/achievementIcons/resourcesSpendtOnStructures/StoneResourcesSpendtOnStructures.png";
import BuilderStage4Badge from "../../images/badges/achievementIcons/resourcesSpendtOnStructures/BronzeResourcesSpendtOnStructures.png";
import BuilderStage5Badge from "../../images/badges/achievementIcons/resourcesSpendtOnStructures/SilverResourcesSpendtOnStructures.png";
import BuilderStage6Badge from "../../images/badges/achievementIcons/resourcesSpendtOnStructures/GoldResourcesSpendtOnStructures.png";
import BuilderStage7Badge from "../../images/badges/achievementIcons/resourcesSpendtOnStructures/EmaraldResourcesSpendtOnStructures.png";
import BuilderStage8Badge from "../../images/badges/achievementIcons/resourcesSpendtOnStructures/SapphireResourcesSpendtOnStructures.png";
import BuilderStage9Badge from "../../images/badges/achievementIcons/resourcesSpendtOnStructures/DiamondResourcesSpendtOnStructures.png";

import WarriorStage1Badge from "../../images/badges/achievementIcons/troopsKilled/CardboardTroopsKilled.png";
import WarriorStage2Badge from "../../images/badges/achievementIcons/troopsKilled/WoodTroopsKilled.png";
import WarriorStage3Badge from "../../images/badges/achievementIcons/troopsKilled/StoneTroopsKilled.png";
import WarriorStage4Badge from "../../images/badges/achievementIcons/troopsKilled/BronzeTroopsKilled.png";
import WarriorStage5Badge from "../../images/badges/achievementIcons/troopsKilled/SilverTroopsKilled.png";
import WarriorStage6Badge from "../../images/badges/achievementIcons/troopsKilled/GoldTroopsKilled.png";
import WarriorStage7Badge from "../../images/badges/achievementIcons/troopsKilled/EmaraldTroopsKilled.png";
import WarriorStage8Badge from "../../images/badges/achievementIcons/troopsKilled/SapphireTroopsKilled.png";
import WarriorStage9Badge from "../../images/badges/achievementIcons/troopsKilled/DiamondTroopsKilled.png";

import AttackerStage1Badge from "../../images/badges/achievementIcons/attacksSendt/CardBoardAttackSendt.png";
import AttackerStage2Badge from "../../images/badges/achievementIcons/attacksSendt/WoodAttackSendt.png";
import AttackerStage3Badge from "../../images/badges/achievementIcons/attacksSendt/StoneAttackSendt.png";
import AttackerStage4Badge from "../../images/badges/achievementIcons/attacksSendt/BronzeAttackSendt.png";
import AttackerStage5Badge from "../../images/badges/achievementIcons/attacksSendt/SilverAttackSendt.png";
import AttackerStage6Badge from "../../images/badges/achievementIcons/attacksSendt/GoldAttackSendt.png";
import AttackerStage7Badge from "../../images/badges/achievementIcons/attacksSendt/EmaraldAttackSendt.png";
import AttackerStage8Badge from "../../images/badges/achievementIcons/attacksSendt/SapphireAttackSendt.png";
import AttackerStage9Badge from "../../images/badges/achievementIcons/attacksSendt/DiamondAttackSendt.png";

import FounderStage1Badge from "../../images/badges/achievementIcons/citiesFounded/CardboardCitiesFounded.png";
import FounderStage2Badge from "../../images/badges/achievementIcons/citiesFounded/WoodCitiesFounded.png";
import FounderStage3Badge from "../../images/badges/achievementIcons/citiesFounded/StoneCitiesFounded.png";
import FounderStage4Badge from "../../images/badges/achievementIcons/citiesFounded/BronzeCitiesFounded.png";
import FounderStage5Badge from "../../images/badges/achievementIcons/citiesFounded/SilverCitiesFounded.png";
import FounderStage6Badge from "../../images/badges/achievementIcons/citiesFounded/GoldCitiesFounded.png";
import FounderStage7Badge from "../../images/badges/achievementIcons/citiesFounded/EmaraldCitiesFounded.png";
import FounderStage8Badge from "../../images/badges/achievementIcons/citiesFounded/SapphireCitiesFounded.png";
import FounderStage9Badge from "../../images/badges/achievementIcons/citiesFounded/DiamondCitiesFounded.png";

import RaiderStage1Badge from "../../images/badges/achievementIcons/resourcesStolen/CardboardResourcesStolen.png";
import RaiderStage2Badge from "../../images/badges/achievementIcons/resourcesStolen/WoodResourcesStolen.png";
import RaiderStage3Badge from "../../images/badges/achievementIcons/resourcesStolen/StoneResourcesStolen.png";
import RaiderStage4Badge from "../../images/badges/achievementIcons/resourcesStolen/BronzeResourcesStolen.png";
import RaiderStage5Badge from "../../images/badges/achievementIcons/resourcesStolen/SilverResourcesStolen.png";
import RaiderStage6Badge from "../../images/badges/achievementIcons/resourcesStolen/GoldResourcesStolen.png";
import RaiderStage7Badge from "../../images/badges/achievementIcons/resourcesStolen/EmaraldResourcesStolen.png";
import RaiderStage8Badge from "../../images/badges/achievementIcons/resourcesStolen/SapphireResourcesStolen.png";
import RaiderStage9Badge from "../../images/badges/achievementIcons/resourcesStolen/DiamondResourcesStolen.png";

import SellerStage1Badge from "../../images/badges/achievementIcons/listingSold/CardboardListingSold.png";
import SellerStage2Badge from "../../images/badges/achievementIcons/listingSold/WoodListingSold.png";
import SellerStage3Badge from "../../images/badges/achievementIcons/listingSold/StoneListingSold.png";
import SellerStage4Badge from "../../images/badges/achievementIcons/listingSold/BronzeListingSold.png";
import SellerStage5Badge from "../../images/badges/achievementIcons/listingSold/SilverListingSold.png";
import SellerStage6Badge from "../../images/badges/achievementIcons/listingSold/GoldListingSold.png";
import SellerStage7Badge from "../../images/badges/achievementIcons/listingSold/EmaraldListingSold.png";
import SellerStage8Badge from "../../images/badges/achievementIcons/listingSold/SapphireListingSold.png";
import SellerStage9Badge from "../../images/badges/achievementIcons/listingSold/DiamondListingSold.png";

import CoinsLostStage1Badge from "../../images/badges/achievementIcons/coinsLost/CardboardCoinsLost.png";
import CoinsLostStage2Badge from "../../images/badges/achievementIcons/coinsLost/WoodCoinsLost.png";
import CoinsLostStage3Badge from "../../images/badges/achievementIcons/coinsLost/StoneCoinsLost.png";
import CoinsLostStage4Badge from "../../images/badges/achievementIcons/coinsLost/BronzeCoinsLost.png";
import CoinsLostStage5Badge from "../../images/badges/achievementIcons/coinsLost/SilverCoinsLost.png";
import CoinsLostStage6Badge from "../../images/badges/achievementIcons/coinsLost/GoldCoinsLost.png";
import CoinsLostStage7Badge from "../../images/badges/achievementIcons/coinsLost/EmaraldCoinsLost.png";
import CoinsLostStage8Badge from "../../images/badges/achievementIcons/coinsLost/SapphireCoinsLost.png";
import CoinsLostStage9Badge from "../../images/badges/achievementIcons/coinsLost/DiamondCoinsLost.png";

import CoinsWonStage1Badge from "../../images/badges/achievementIcons/coinsWon/CardboardCoinsWon.png";
import CoinsWonStage2Badge from "../../images/badges/achievementIcons/coinsWon/WoodCoinsWon.png";
import CoinsWonStage3Badge from "../../images/badges/achievementIcons/coinsWon/StoneCoinsWon.png";
import CoinsWonStage4Badge from "../../images/badges/achievementIcons/coinsWon/BronzeCoinsWon.png";
import CoinsWonStage5Badge from "../../images/badges/achievementIcons/coinsWon/SilverCoinsWon.png";
import CoinsWonStage6Badge from "../../images/badges/achievementIcons/coinsWon/GoldCoinsWon.png";
import CoinsWonStage7Badge from "../../images/badges/achievementIcons/coinsWon/EmaraldCoinsWon.png";
import CoinsWonStage8Badge from "../../images/badges/achievementIcons/coinsWon/SapphireCoinsWon.png";
import CoinsWonStage9Badge from "../../images/badges/achievementIcons/coinsWon/DiamondCoinsWon.png";

import ChestsOpenedStage1Badge from "../../images/badges/achievementIcons/chestsOpened/cardboardChestsOpened.png";
import ChestsOpenedStage2Badge from "../../images/badges/achievementIcons/chestsOpened/WoodChestsOpened.png";
import ChestsOpenedStage3Badge from "../../images/badges/achievementIcons/chestsOpened/StoneChestsOpened.png";
import ChestsOpenedStage4Badge from "../../images/badges/achievementIcons/chestsOpened/BronzeChestsOpened.png";
import ChestsOpenedStage5Badge from "../../images/badges/achievementIcons/chestsOpened/SilverChestsOpened.png";
import ChestsOpenedStage6Badge from "../../images/badges/achievementIcons/chestsOpened/GoldChestsOpened.png";
import ChestsOpenedStage7Badge from "../../images/badges/achievementIcons/chestsOpened/EmaraldChestsOpened.png";
import ChestsOpenedStage8Badge from "../../images/badges/achievementIcons/chestsOpened/SapphireChestsOpened.png";
import ChestsOpenedStage9Badge from "../../images/badges/achievementIcons/chestsOpened/DiamondChestsOpened.png";

import MainWinnerBadge from "../../images/badges/season1Winners/MainWinner.png";
import LooserBlackjackBadge from "../../images/badges/season1Winners/LooserBlackjack.png";
import LooserSlotsBadge from "../../images/badges/season1Winners/LooserSlots.png";
import LooserTroopsBadge from "../../images/badges/season1Winners/LooserTroops.png";
import LooserTroopsAllianceBadge from "../../images/badges/season1Winners/LooserTroopsAlliance.png";
import MostPopulatedAllianceBadge from "../../images/badges/season1Winners/MostPopulatedAlliance.png";
import MostPopulatedPlayerBadge from "../../images/badges/season1Winners/MostPopulatedPlayer.png";
import ResourcesLostBadge from "../../images/badges/season1Winners/ResourcesLost.png";
import ResourcesLostAllianceBadge from "../../images/badges/season1Winners/ResourcesLostAlliance.png";
import ResourcesStolenBadge from "../../images/badges/season1Winners/ResourcesStolen.png";
import ResourcesStolenAllianceBadge from "../../images/badges/season1Winners/ResourcesStolenAlliance.png";
import SecondMostPopulatedAllianceBadge from "../../images/badges/season1Winners/secondMostPopulatedAlliance.png";
import SecondMostPopulatedPlayerBadge from "../../images/badges/season1Winners/SecondMostPopulatedPlayer.png";
import ThirdMostPopulatedAllianceBadge from "../../images/badges/season1Winners/ThirdMostPopulatedAlliance.png";
import ThirdMostPopulatedPlayerBadge from "../../images/badges/season1Winners/ThirdMostPopulatedPlayer.png";
import TroopWinnerBadge from "../../images/badges/season1Winners/TroopWinner.png";
import TroopWinnerAllianceBadge from "../../images/badges/season1Winners/TroopWinnerAlliance.png";
import WinnerBlackjackBadge from "../../images/badges/season1Winners/WinnerBlackjack.png";
import WinnerSlotsBadge from "../../images/badges/season1Winners/WinnerSlots.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { React } from "react";

export function BadgeCard(props) {
  const badgeImgClasses = `m-2 cursor-pointer min-w-[40px] max-w-[70px] relative ${
    props.isSelected ? "ring-4 ring-blue-500" : ""
  } border 
    ${
      props.badge.type === `seller`
        ? "border-blue-500"
        : props.badge.type === "attacker"
        ? "border-red-500"
        : props.badge.type === "casinoWinner"
        ? "border-green-500"
        : props.badge.type === "casinoLooser"
        ? "border-yellow-500"
        : props.badge.type === "chestsOpened"
        ? "border-purple-500"
        : props.badge.type === "warrior"
        ? "border-gray-500"
        : props.badge.type === "builder"
        ? "border-orange-500"
        : props.badge.type === "founder"
        ? "border-indigo-500"
        : props.badge.type === "raider"
        ? "border-emerald-500"
        : "border-black"
    }`;
  const badgeClickHandler = props.isClickable
    ? () => props.handleBadgeClick(props.badge.badge_id)
    : undefined;

  return (
    <div
      onClick={badgeClickHandler}
      className={badgeImgClasses}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      {/*     type: {props.badge.type}
        stage: {props.badge.stage}*/}
      {props.badge.type === "builder" && props.badge.stage === 1 && (
        <img src={BuilderStage1Badge} alt="Builder badge stage 1" />
      )}
      {props.badge.type === "builder" && props.badge.stage === 2 && (
        <img src={BuilderStage2Badge} alt="Builder badge stage 2" />
      )}
      {props.badge.type === "builder" && props.badge.stage === 3 && (
        <img src={BuilderStage3Badge} alt="Builder badge stage 3" />
      )}
      {props.badge.type === "builder" && props.badge.stage === 4 && (
        <img src={BuilderStage4Badge} alt="Builder badge stage 4" />
      )}
      {props.badge.type === "builder" && props.badge.stage === 5 && (
        <img src={BuilderStage5Badge} alt="Builder badge stage 5" />
      )}
      {props.badge.type === "builder" && props.badge.stage === 6 && (
        <img src={BuilderStage6Badge} alt="Builder badge stage 6" />
      )}
      {props.badge.type === "builder" && props.badge.stage === 7 && (
        <img src={BuilderStage7Badge} alt="Builder badge stage 7" />
      )}
      {props.badge.type === "builder" && props.badge.stage === 8 && (
        <img src={BuilderStage8Badge} alt="Builder badge stage 8" />
      )}
      {props.badge.type === "builder" && props.badge.stage === 9 && (
        <img src={BuilderStage9Badge} alt="Builder badge stage 9" />
      )}

      {props.badge.type === "warrior" && props.badge.stage === 1 && (
        <img src={WarriorStage1Badge} alt="Warrior badge stage 1" />
      )}
      {props.badge.type === "warrior" && props.badge.stage === 2 && (
        <img src={WarriorStage2Badge} alt="Warrior badge stage 2" />
      )}
      {props.badge.type === "warrior" && props.badge.stage === 3 && (
        <img src={WarriorStage3Badge} alt="Warrior badge stage 3" />
      )}
      {props.badge.type === "warrior" && props.badge.stage === 4 && (
        <img src={WarriorStage4Badge} alt="Warrior badge stage 4" />
      )}
      {props.badge.type === "warrior" && props.badge.stage === 5 && (
        <img src={WarriorStage5Badge} alt="Warrior badge stage 5" />
      )}
      {props.badge.type === "warrior" && props.badge.stage === 6 && (
        <img src={WarriorStage6Badge} alt="Warrior badge stage 6" />
      )}
      {props.badge.type === "warrior" && props.badge.stage === 7 && (
        <img src={WarriorStage7Badge} alt="Warrior badge stage 7" />
      )}
      {props.badge.type === "warrior" && props.badge.stage === 8 && (
        <img src={WarriorStage8Badge} alt="Warrior badge stage 8" />
      )}
      {props.badge.type === "warrior" && props.badge.stage === 9 && (
        <img src={WarriorStage9Badge} alt="Warrior badge stage 9" />
      )}

      {props.badge.type === "attacker" && props.badge.stage === 1 && (
        <img src={AttackerStage1Badge} alt="Attacker badge stage 1" />
      )}
      {props.badge.type === "attacker" && props.badge.stage === 2 && (
        <img src={AttackerStage2Badge} alt="Attacker badge stage 2" />
      )}
      {props.badge.type === "attacker" && props.badge.stage === 3 && (
        <img src={AttackerStage3Badge} alt="Attacker badge stage 3" />
      )}
      {props.badge.type === "attacker" && props.badge.stage === 4 && (
        <img src={AttackerStage4Badge} alt="Attacker badge stage 4" />
      )}
      {props.badge.type === "attacker" && props.badge.stage === 5 && (
        <img src={AttackerStage5Badge} alt="Attacker badge stage 5" />
      )}
      {props.badge.type === "attacker" && props.badge.stage === 6 && (
        <img src={AttackerStage6Badge} alt="Attacker badge stage 6" />
      )}
      {props.badge.type === "attacker" && props.badge.stage === 7 && (
        <img src={AttackerStage7Badge} alt="Attacker badge stage 7" />
      )}
      {props.badge.type === "attacker" && props.badge.stage === 8 && (
        <img src={AttackerStage8Badge} alt="Attacker badge stage 8" />
      )}
      {props.badge.type === "attacker" && props.badge.stage === 9 && (
        <img src={AttackerStage9Badge} alt="Attacker badge stage 9" />
      )}

      {props.badge.type === "founder" && props.badge.stage === 1 && (
        <img src={FounderStage1Badge} alt="Founder badge stage 1" />
      )}
      {props.badge.type === "founder" && props.badge.stage === 2 && (
        <img src={FounderStage2Badge} alt="Founder badge stage 2" />
      )}
      {props.badge.type === "founder" && props.badge.stage === 3 && (
        <img src={FounderStage3Badge} alt="Founder badge stage 3" />
      )}
      {props.badge.type === "founder" && props.badge.stage === 4 && (
        <img src={FounderStage4Badge} alt="Founder badge stage 4" />
      )}
      {props.badge.type === "founder" && props.badge.stage === 5 && (
        <img src={FounderStage5Badge} alt="Founder badge stage 5" />
      )}
      {props.badge.type === "founder" && props.badge.stage === 6 && (
        <img src={FounderStage6Badge} alt="Founder badge stage 6" />
      )}
      {props.badge.type === "founder" && props.badge.stage === 7 && (
        <img src={FounderStage7Badge} alt="Founder badge stage 7" />
      )}
      {props.badge.type === "founder" && props.badge.stage === 8 && (
        <img src={FounderStage8Badge} alt="Founder badge stage 8" />
      )}
      {props.badge.type === "founder" && props.badge.stage === 9 && (
        <img src={FounderStage9Badge} alt="Founder badge stage 9" />
      )}

      {props.badge.type === "raider" && props.badge.stage === 1 && (
        <img src={RaiderStage1Badge} alt="Raider badge stage 1" />
      )}
      {props.badge.type === "raider" && props.badge.stage === 2 && (
        <img src={RaiderStage2Badge} alt="Raider badge stage 2" />
      )}
      {props.badge.type === "raider" && props.badge.stage === 3 && (
        <img src={RaiderStage3Badge} alt="Raider badge stage 3" />
      )}
      {props.badge.type === "raider" && props.badge.stage === 4 && (
        <img src={RaiderStage4Badge} alt="Raider badge stage 4" />
      )}
      {props.badge.type === "raider" && props.badge.stage === 5 && (
        <img src={RaiderStage5Badge} alt="Raider badge stage 5" />
      )}
      {props.badge.type === "raider" && props.badge.stage === 6 && (
        <img src={RaiderStage6Badge} alt="Raider badge stage 6" />
      )}
      {props.badge.type === "raider" && props.badge.stage === 7 && (
        <img src={RaiderStage7Badge} alt="Raider badge stage 7" />
      )}
      {props.badge.type === "raider" && props.badge.stage === 8 && (
        <img src={RaiderStage8Badge} alt="Raider badge stage 8" />
      )}
      {props.badge.type === "raider" && props.badge.stage === 9 && (
        <img src={RaiderStage9Badge} alt="Raider badge stage 9" />
      )}

      {props.badge.type === "seller" && props.badge.stage === 1 && (
        <img src={SellerStage1Badge} alt="Seller badge stage 1" />
      )}
      {props.badge.type === "seller" && props.badge.stage === 2 && (
        <img src={SellerStage2Badge} alt="Seller badge stage 2" />
      )}
      {props.badge.type === "seller" && props.badge.stage === 3 && (
        <img src={SellerStage3Badge} alt="Seller badge stage 3" />
      )}
      {props.badge.type === "seller" && props.badge.stage === 4 && (
        <img src={SellerStage4Badge} alt="Seller badge stage 4" />
      )}
      {props.badge.type === "seller" && props.badge.stage === 5 && (
        <img src={SellerStage5Badge} alt="Seller badge stage 5" />
      )}
      {props.badge.type === "seller" && props.badge.stage === 6 && (
        <img src={SellerStage6Badge} alt="Seller badge stage 6" />
      )}
      {props.badge.type === "seller" && props.badge.stage === 7 && (
        <img src={SellerStage7Badge} alt="Seller badge stage 7" />
      )}
      {props.badge.type === "seller" && props.badge.stage === 8 && (
        <img src={SellerStage8Badge} alt="Seller badge stage 8" />
      )}
      {props.badge.type === "seller" && props.badge.stage === 9 && (
        <img src={SellerStage9Badge} alt="Seller badge stage 9" />
      )}

      {props.badge.type === "casinoLooser" && props.badge.stage === 1 && (
        <img src={CoinsLostStage1Badge} alt="Casino Looser badge stage 1" />
      )}
      {props.badge.type === "casinoLooser" && props.badge.stage === 2 && (
        <img src={CoinsLostStage2Badge} alt="Casino Looser badge stage 2" />
      )}
      {props.badge.type === "casinoLooser" && props.badge.stage === 3 && (
        <img src={CoinsLostStage3Badge} alt="Casino Looser badge stage 3" />
      )}
      {props.badge.type === "casinoLooser" && props.badge.stage === 4 && (
        <img src={CoinsLostStage4Badge} alt="Casino Looser badge stage 4" />
      )}
      {props.badge.type === "casinoLooser" && props.badge.stage === 5 && (
        <img src={CoinsLostStage5Badge} alt="Casino Looser badge stage 5" />
      )}
      {props.badge.type === "casinoLooser" && props.badge.stage === 6 && (
        <img src={CoinsLostStage6Badge} alt="Casino Looser badge stage 6" />
      )}
      {props.badge.type === "casinoLooser" && props.badge.stage === 7 && (
        <img src={CoinsLostStage7Badge} alt="Casino Looser badge stage 7" />
      )}
      {props.badge.type === "casinoLooser" && props.badge.stage === 8 && (
        <img src={CoinsLostStage8Badge} alt="Casino Looser badge stage 8" />
      )}
      {props.badge.type === "casinoLooser" && props.badge.stage === 9 && (
        <img src={CoinsLostStage9Badge} alt="Casino Looser badge stage 9" />
      )}

      {props.badge.type === "casinoWinner" && props.badge.stage === 1 && (
        <img src={CoinsWonStage1Badge} alt="Casino Winner badge stage 1" />
      )}
      {props.badge.type === "casinoWinner" && props.badge.stage === 2 && (
        <img src={CoinsWonStage2Badge} alt="Casino Winner badge stage 2" />
      )}
      {props.badge.type === "casinoWinner" && props.badge.stage === 3 && (
        <img src={CoinsWonStage3Badge} alt="Casino Winner badge stage 3" />
      )}
      {props.badge.type === "casinoWinner" && props.badge.stage === 4 && (
        <img src={CoinsWonStage4Badge} alt="Casino Winner badge stage 4" />
      )}
      {props.badge.type === "casinoWinner" && props.badge.stage === 5 && (
        <img src={CoinsWonStage5Badge} alt="Casino Winner badge stage 5" />
      )}
      {props.badge.type === "casinoWinner" && props.badge.stage === 6 && (
        <img src={CoinsWonStage6Badge} alt="Casino Winner badge stage 6" />
      )}
      {props.badge.type === "casinoWinner" && props.badge.stage === 7 && (
        <img src={CoinsWonStage7Badge} alt="Casino Winner badge stage 7" />
      )}
      {props.badge.type === "casinoWinner" && props.badge.stage === 8 && (
        <img src={CoinsWonStage8Badge} alt="Casino Winner badge stage 8" />
      )}
      {props.badge.type === "casinoWinner" && props.badge.stage === 9 && (
        <img src={CoinsWonStage9Badge} alt="Casino Winner badge stage 9" />
      )}

      {props.badge.type === "chestsOpened" && props.badge.stage === 1 && (
        <img src={ChestsOpenedStage1Badge} alt="Chests opened badge stage 1" />
      )}
      {props.badge.type === "chestsOpened" && props.badge.stage === 2 && (
        <img src={ChestsOpenedStage2Badge} alt="Chests opened badge stage 2" />
      )}
      {props.badge.type === "chestsOpened" && props.badge.stage === 3 && (
        <img src={ChestsOpenedStage3Badge} alt="Chests opened badge stage 3" />
      )}
      {props.badge.type === "chestsOpened" && props.badge.stage === 4 && (
        <img src={ChestsOpenedStage4Badge} alt="Chests opened badge stage 4" />
      )}
      {props.badge.type === "chestsOpened" && props.badge.stage === 5 && (
        <img src={ChestsOpenedStage5Badge} alt="Chests opened badge stage 5" />
      )}
      {props.badge.type === "chestsOpened" && props.badge.stage === 6 && (
        <img src={ChestsOpenedStage6Badge} alt="Chests opened badge stage 6" />
      )}
      {props.badge.type === "chestsOpened" && props.badge.stage === 7 && (
        <img src={ChestsOpenedStage7Badge} alt="Chests opened badge stage 7" />
      )}
      {props.badge.type === "chestsOpened" && props.badge.stage === 8 && (
        <img src={ChestsOpenedStage8Badge} alt="Chests opened badge stage 8" />
      )}
      {props.badge.type === "chestsOpened" && props.badge.stage === 9 && (
        <img src={ChestsOpenedStage9Badge} alt="Chests opened badge stage 9" />
      )}

      {props.badge.type === "winner" &&
        props.badge.name === "Emperor of Acquisition" && (
          <img
            src={MainWinnerBadge}
            alt={`Badge icon for ${props.badge.description}`}
          />
        )}

      {props.badge.type === "winner" &&
        props.badge.name === "Emperor of War" && (
          <img
            src={MainWinnerBadge}
            alt={`Badge icon for ${props.badge.description}`}
          />
        )}

      {props.badge.type === "winner" && props.badge.name === "Basic Beta" && (
        <img
          src={MainWinnerBadge}
          alt={`Badge icon for ${props.badge.description}`}
        />
      )}

      {props.badge.type === "winner" && props.badge.name === "City lord" && (
        <img
          src={MostPopulatedPlayerBadge}
          alt={`Badge icon for ${props.badge.description}`}
        />
      )}

      {props.badge.type === "winner" &&
        props.badge.name === "Village Chief" && (
          <img
            src={SecondMostPopulatedPlayerBadge}
            alt={`Badge icon for ${props.badge.description}`}
          />
        )}

      {props.badge.type === "winner" &&
        props.badge.name === "Third Best Beta" && (
          <img
            src={ThirdMostPopulatedPlayerBadge}
            alt={`Badge icon for ${props.badge.description}`}
          />
        )}

      {props.badge.type === "winner" &&
        props.badge.name === "Brother in arms" && (
          <img
            src={MostPopulatedAllianceBadge}
            alt={`Badge icon for ${props.badge.description}`}
          />
        )}
      {props.badge.type === "winner" &&
        props.badge.name === "Second Best Alliance Beta" && (
          <img
            src={SecondMostPopulatedAllianceBadge}
            alt={`Badge icon for ${props.badge.description}`}
          />
        )}

      {props.badge.type === "winner" &&
        props.badge.name === "Third Best Alliance Beta" && (
          <img
            src={ThirdMostPopulatedAllianceBadge}
            alt={`Badge icon for ${props.badge.description}`}
          />
        )}

      {props.badge.type === "winner" && props.badge.name === "Warlord" && (
        <img
          src={TroopWinnerBadge}
          alt={`Badge icon for ${props.badge.description}`}
        />
      )}

      {props.badge.type === "winner" && props.badge.name === "Guardian" && (
        <img
          src={LooserTroopsBadge}
          alt={`Badge icon for ${props.badge.description}`}
        />
      )}

      {props.badge.type === "winner" &&
        props.badge.name === "The Golden Claw" && (
          <img
            src={ResourcesStolenBadge}
            alt={`Badge icon for ${props.badge.description}`}
          />
        )}

      {props.badge.type === "winner" &&
        props.badge.name === "Community Coffer" && (
          <img
            src={ResourcesLostBadge}
            alt={`Badge icon for ${props.badge.description}`}
          />
        )}

      {props.badge.type === "winner" &&
        props.badge.name === "Spinner of Fortune" && (
          <img
            src={WinnerSlotsBadge}
            alt={`Badge icon for ${props.badge.description}`}
          />
        )}

      {props.badge.type === "winner" &&
        props.badge.name === "The High Roller" && (
          <img
            src={WinnerBlackjackBadge}
            alt={`Badge icon for ${props.badge.description}`}
          />
        )}

      {props.badge.type === "winner" &&
        props.badge.name === "Slot beta looser" && (
          <img
            src={LooserSlotsBadge}
            alt={`Badge icon for ${props.badge.description}`}
          />
        )}

      {props.badge.type === "winner" &&
        props.badge.name === "Blackjack beta looser" && (
          <img
            src={LooserBlackjackBadge}
            alt={`Badge icon for ${props.badge.description}`}
          />
        )}

      {props.badge.type === "winner" &&
        props.badge.name === "Troop killer alliance" && (
          <img
            src={TroopWinnerAllianceBadge}
            alt={`Badge icon for ${props.badge.description}`}
          />
        )}

      {props.badge.type === "winner" &&
        props.badge.name === "Troop sacrifice alliance" && (
          <img
            src={LooserTroopsAllianceBadge}
            alt={`Badge icon for ${props.badge.description}`}
          />
        )}

      {props.badge.type === "winner" &&
        props.badge.name === "Resources gained alliance" && (
          <img
            src={ResourcesStolenAllianceBadge}
            alt={`Badge icon for ${props.badge.description}`}
          />
        )}

      {props.badge.type === "winner" &&
        props.badge.name === "Resources lost alliance" && (
          <img
            src={ResourcesLostAllianceBadge}
            alt={`Badge icon for ${props.badge.description}`}
          />
        )}

      <div className="absolute bottom-0 right-0">
        <FontAwesomeIcon icon={faInfo} className="text-black " />
      </div>
    </div>
  );
}
