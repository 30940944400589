import { useEffect, useState } from "react";
import { StyledUser } from "./StyledUser";

export function SelectNameColorComponent({
  selectedNameColor,
  setSelectedNameColor,
  availableColors,
  username,
}) {
  const [allColors, setAllColors] = useState(availableColors);

  useEffect(() => {
    if (
      availableColors.length > 0 &&
      allColors.length <= availableColors.length
    ) {
      const find = allColors.find((ic) => ic === "null");
      if (!find) {
        allColors.push("null");
      }
    }
  }, [availableColors]);
  return (
    <div>
      <h4 className="mb-2 text-lg font-semibold text-gray-50">
        Choose name colors:
      </h4>
      {allColors.length > 0 ? (
        <div className="flex">
          {allColors.map((color) => (
            <div
              className={`${
                color === selectedNameColor &&
                "border-2 border-black rounded-lg "
              }
             ${
               color === "null" &&
               selectedNameColor === null &&
               "border-2 border-black rounded-lg "
             }  p-2 font-semibold`}
              onClick={() => setSelectedNameColor(color)}
            >
              <StyledUser username={username} nameColor={color} />
            </div>
          ))}
        </div>
      ) : (
        <div>No available colors</div>
      )}
    </div>
  );
}
