import { useState } from "react";
import { CalculatorTroopInfo } from "./CalculatorTroopInfo";
import { CombatCalculatorResult } from "./CombatCalculatorResult";

export function CombatCalculator() {
  const [attackerTroops, setAttackerTroops] = useState({
    troop1: 0,
    troop2: 0,
    troop3: 0,
    troop4: 0,
    troop5: 0,
    troop6: 0,
    troop7: 0,
    troop8: 0,
    troop9: 0,
  });
  const [defenderTroops, setDefenderTroops] = useState({
    troop1: 0,
    troop2: 0,
    troop3: 0,
    troop4: 0,
    troop5: 0,
    troop6: 0,
    troop7: 0,
    troop8: 0,
    troop9: 0,
  });

  const [attackerBonus, setAttackerBonus] = useState(0);
  const [defenderBonus, setDefenderBonus] = useState(0);
  // State for collapsing
  const [isAttackerOpen, setIsAttackerOpen] = useState(true);
  const [isDefenderOpen, setIsDefenderOpen] = useState(true);
  const [result, setResult] = useState(undefined);
  const [error, setError] = useState(undefined);
  async function calculateAttack(e) {
    e.preventDefault();
    const obj = {
      attackerTroops: attackerTroops,
      defenderTroops: defenderTroops,
      cityAttackBonus: attackerBonus,
      cityDefenceBonus: defenderBonus,
    };
    const res = await fetch(
      `${import.meta.env.VITE_GAMEHOST}/api/troops/calculator`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(obj),
        credentials: "include",
      }
    );

    if (res.status === 200) {
      const data = await res.json();
      setResult(data);
    } else {
      setError("Something went wrong");
    }
  }

  return (
    <div className="p-4 text-white">
      <h2 className="text-2xl font-bold mb-4 text-center">Combat Calculator</h2>

      <form onSubmit={calculateAttack}>
        {/* Attacker Army Section */}
        <div className="mb-4 border ">
          <button
            className="w-full text-left p-3 bg-mainAttack rounded-t-lg flex justify-between"
            onClick={() => setIsAttackerOpen(!isAttackerOpen)}
          >
            <span className="font-semibold">Attacker Army</span>
            <span>{isAttackerOpen ? "▲" : "▼"}</span>
          </button>
          {isAttackerOpen && (
            <div className="p-3 bg-calculatorDefenderBG rounded-b-lg">
              <CalculatorTroopInfo
                troops={attackerTroops}
                setTroops={setAttackerTroops}
                setBonus={setAttackerBonus}
                bonus={attackerBonus}
              />
            </div>
          )}
        </div>

        {/* Defender Army Section */}
        <div className="mb-4 border rounded-lg">
          <button
            className="w-full text-left p-3 bg-mainDefend rounded-t-lg flex justify-between"
            onClick={() => setIsDefenderOpen(!isDefenderOpen)}
          >
            <span className="font-semibold">Defender Army</span>
            <span>{isDefenderOpen ? "▲" : "▼"}</span>
          </button>
          {isDefenderOpen && (
            <div className="p-3 bg-calculatorDefenderBG  rounded-b-lg">
              <CalculatorTroopInfo
                troops={defenderTroops}
                setTroops={setDefenderTroops}
                setBonus={setDefenderBonus}
                bonus={defenderBonus}
              />
            </div>
          )}
        </div>

        <div className="flex justify-center mb-6">
          <button type="submit" className="bg-mainAttack3">
            Calculate attack
          </button>
        </div>
      </form>

      {error && <div className="text-red-500">{error}</div>}
      {result && <CombatCalculatorResult result={result} />}
    </div>
  );
}
