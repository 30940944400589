import { useContext } from "react";
import { UserContext } from "../../index";
import { Link } from "react-router-dom";
import { WheatIconResponsive } from "../../images/resources/WheatIconResponsive";
import { WoodIconReponsive } from "../../images/resources/WoodIconReponsive";
import { StoneIconResponsive } from "../../images/resources/StoneIconResponsive";
import { IronIconResponsive } from "../../images/resources/IronIconResponsive";
import ClubManIcon from "../../images/troops/ClubmanIcon.png";
import SpearManIcon from "../../images/troops/SpearmanIcon.png";
import AxeMenIcon from "../../images/troops/AxemanIcon.png";
import FounderIcon from "../../images/troops/FounderIcon.png";
import Warlord from "../../images/troops/WarlordIcon.png";
import Guardian from "../../images/troops/GuardianIcon.png";
import Scout from "../../images/troops/ScoutIcon.png";
import Catapult from "../../images/troops/CatapultIcon.png";
import { HelperFunctions } from "../../HelperFunctions";
import { JadeIconResponsive } from "../../images/resources/JadeIconResponsive";

export function AttackActivityCard(props) {
  const { userInfo } = useContext(UserContext);

  function renderTroopHeaders(troopAmounts) {
    const troops = [
      ClubManIcon,
      SpearManIcon,
      AxeMenIcon,
      FounderIcon,
      Warlord,
      Guardian,
      Scout,
      Catapult,
    ];
    return (
      <div className="grid grid-cols-2 gap-2 justify-center place-items-center">
        {troops.map((troopConstant, index) => (
          <>
            {troopAmounts[index] > 0 && (
              <div key={index}>
                <img
                  className="w-6 h-6"
                  src={troopConstant}
                  alt="Icon of troop "
                />
                <h4 className="inline">
                  {props.type === "incoming" ||
                  props.type === "incomingReinforcement"
                    ? "??????"
                    : troopAmounts[index]}
                </h4>
              </div>
            )}
          </>
        ))}
      </div>
    );
  }

  return (
    <div className="bg-mainLightGreen2 p-2  text-xs sm:text-sm md-text-base">
      <div>
        {props.type !== "incomingReinforcement" &&
          props.type !== "incoming" && (
            <>
              {renderTroopHeaders([
                props.data.troop1,
                props.data.troop2,
                props.data.troop3,
                props.data.troop4,
                props.data.troop5,
                props.data.troop6,
                props.data.troop7,
                props.data.troop8,
              ])}
            </>
          )}
      </div>

      <div className="bg-mainLightGreen3 p-2">
        <div className="text-center ">
          {props.type === "outgoing" ||
            (props.type === "outgoingReinforcement" && (
              <div>
                <h4 className="flex justify-center">
                  {props.data.attackerCityName}
                </h4>
                <h4>
                  {props.type === "outgoing" ? "Attacking" : "Reinforcing"}{" "}
                </h4>
                <h4 className="flex justify-center">
                  {props.data.defenderCityName}
                  <Link
                    to={`/profile/${
                      userInfo.username === props.data.defenderUsername
                        ? "me"
                        : props.data.defenderUsername
                    }`}
                  >
                    ({props.data.defenderUsername})
                  </Link>
                </h4>
              </div>
            ))}

          {props.type === "incoming" ||
            (props.type === "incomingReinforcement" && (
              <div>
                <h4 className="flex justify-center">
                  {props.data.attackerCityName}{" "}
                  <Link
                    to={`/profile/${
                      userInfo.username === props.data.attackerUsername
                        ? "me"
                        : props.data.attackerUsername
                    }`}
                  >
                    ({props.data.attackerUsername})
                  </Link>
                </h4>
                <h4>
                  {props.type === "incoming" ? "Attacking" : "reinforcing"}
                </h4>
                <h4 className="flex justify-center">
                  {props.data.defenderCityName}
                </h4>
              </div>
            ))}
          {props.type === "returning" && (
            <div>
              <h4>
                Returning from {props.data.fromCityName}{" "}
                <Link
                  to={`/profile/${
                    userInfo.username === props.data.fromUsername
                      ? "me"
                      : props.data.fromUsername
                  }`}
                >
                  ({props.data.fromUsername})
                </Link>
              </h4>

              <div className="grid grid-cols-2 gap-2 place-items-center">
                <div className="flex">
                  <WheatIconResponsive />
                  <h4>{props.data.wheat}</h4>
                </div>

                <div className="flex">
                  <WoodIconReponsive />
                  <h4>{props.data.wood}</h4>
                </div>

                <div className="flex">
                  <StoneIconResponsive />
                  <h4>{props.data.stone}</h4>
                </div>

                <div className="flex">
                  <IronIconResponsive />
                  <h4>{props.data.iron}</h4>
                </div>

                {props.data.jade > 0 && (
                  <div className="flex">
                    <JadeIconResponsive />
                    <h4>{props.data.jade}</h4>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div className="text-center">
          Arrives in{" "}
          {props.type === "outgoing" || props.type === "outgoingReinforcement"
            ? `${HelperFunctions.secondsToTimestamp(props.data.timeLeft)}`
            : props.type === "incoming" ||
              props.type === "returning" ||
              props.type === "incomingReinforcement"
            ? HelperFunctions.secondsToTimestamp(props.data.timeLeft)
            : "Unknown"}{" "}
          seconds
        </div>
      </div>
    </div>
    /* <div className="bg-mainAttack m-2 p-4 rounded-md">
        <p >

        </p>
        <table className="table-auto w-full mb-6 border-collapse">
          <thead>
            <tr>{renderTroopHeaders()}</tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-center border border-black px-4 py-2">
                {props.data.attackdetails.troopAttackedWithAmount.troop1}
              </td>
              <td className="text-center border border-black px-4 py-2">
                {props.data.attackdetails.troopAttackedWithAmount.troop2}
              </td>
              <td className="text-center border border-black px-4 py-2">
                {props.data.attackdetails.troopAttackedWithAmount.troop3}
              </td>
              <td className="text-center border border-black px-4 py-2">
                {props.data.attackdetails.troopAttackedWithAmount.troop4}
              </td>
            </tr>
          </tbody>
        </table>
        <p className="text-center">Arrives in {props.data.attackInfo.timeLeft} seconds</p>
      </div>*/
  );

  /*  if (props.type === "incoming") {
    return (
      <div className="bg-mainDefend m-2 p-4 rounded-md">
        <p>
          {props.data.attackerInfo.attackerCityName} Attacking{" "}
          {props.data.defenderInfo.defenderCityName}
        </p>
        <table className="table-auto w-full mb-6 border-collapse">
          <thead>
            <tr>{renderTroopHeaders(CONSTANTS)}</tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-center border border-black px-4 py-2">
                ?????
              </td>
              <td className="text-center border border-black px-4 py-2">
                ?????
              </td>
              <td className="text-center border border-black px-4 py-2">
                ?????
              </td>
              <td className="text-center border border-black px-4 py-2">
                ?????
              </td>
            </tr>
          </tbody>
        </table>
        <p className="text-center">Arrives in {props.data.timeLeft} seconds</p>
      </div>
    );
  }*/

  return <div>No data available</div>;
}
