import { useEffect, useState } from "react";

export function SelectNameTitleComponent({
  selectedNameTitle,
  setSelectedNameTitle,
  availableTitles,
}) {
  const [allTitles, setAllTitles] = useState(availableTitles);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (availableTitles.length > 0) {
      setAllTitles([...availableTitles, { name: null }]);
    }
  }, [availableTitles]);

  return (
    <div className="relative w-full max-w-xs">
      <h4 className="mb-2 text-lg font-semibold text-gray-50">
        Choose name title:
      </h4>

      <button
        className="w-full flex items-center justify-between border border-gray-300 bg-white rounded-lg px-4 py-2 text-gray-700 shadow-sm hover:bg-gray-50 transition"
        onClick={() => setIsOpen(!isOpen)}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
      >
        {selectedNameTitle || "Select a title"}
        <span className={`transition-transform ${isOpen ? "rotate-180" : ""}`}>
          ▼
        </span>
      </button>

      {isOpen && (
        <div className="absolute left-0 mt-2 w-full bg-white border border-gray-300 rounded-lg shadow-lg overflow-hidden z-10">
          <ul className="max-h-60 overflow-y-auto">
            {allTitles.map((title, index) => (
              <li
                key={index}
                className={`px-4 py-2 flex items-center justify-between text-gray-700 cursor-pointer hover:bg-gray-100 transition ${
                  selectedNameTitle === title.name ? "bg-gray-200" : ""
                }`}
                onClick={() => {
                  setSelectedNameTitle(title.name);
                  setIsOpen(false);
                }}
                role="option"
              >
                {title.name || "No title"}
                {selectedNameTitle === title.name && (
                  <span className="text-green-600">✔</span>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
