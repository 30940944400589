import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CityConfigContext, UserContext } from "../../index.jsx";
import { MapAttackCard } from "./MapAttackCard";
import { SendResFromMapCard } from "./SendResFromMapCard";
import { StyledUser } from "../StyledUser.jsx";
import { CONSTANTS } from "../../constants.js";

export function MapCityCard(props) {
  const { userInfo } = useContext(UserContext);
  const { cityRestrictions } = useContext(CityConfigContext);

  const [cityData, setCityData] = useState();
  const [isMyCity, setIsMyCity] = useState(false);
  const [showAttackMenu, setShowAttackMenu] = useState(false);
  const [showSendResMenu, setShowSendResMenu] = useState(false);
  const { inCityId } = useContext(CityConfigContext);
  const [actionMode, setActionMode] = useState(null);

  function hideAttackMenu() {
    setShowAttackMenu(false);
    setActionMode(null);
  }

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(
        `${import.meta.env.VITE_GAMEHOST}/api/map/city/${
          props.tileData.cityId
        }`,
        {
          credentials: "include",
        }
      );

      const data = await res.json();

      setCityData(data);
      if (userInfo.username === data.username) {
        setIsMyCity(true);
      } else {
        setIsMyCity(false);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    if (cityData) {
      if (cityData.city_id === inCityId) {
        setIsMyCity(true);
      } else {
        setIsMyCity(false);
      }
    }
  }, [inCityId, cityData]);

  if (!cityData) {
    return <div>Loading...</div>;
  }
  if (!actionMode) {
    return (
      <div className="text-white">
        <h3 className="text-xs xs:text-sm sm:text-base md:text-xl font-semibold mb-4 text-center">
          {props.tileData.city.cityName}
        </h3>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 place-items-center text-sm md:text-base">
          <div>
            <p>
              User:{" "}
              <Link
                className=" font-semibold hover:underline"
                to={
                  cityData.username !== "botlin"
                    ? `/profile/${cityData.username}`
                    : "/town"
                }
              >
                <StyledUser
                  username={cityData.username}
                  nameColor={cityData.namecolor}
                  nameIcon={cityData.nameicon}
                  nameTitle={cityData.nametitle}
                />
              </Link>
              {(cityData.username === CONSTANTS.CURRENT_ENDGAME_NPC ||
                cityData.username === "botlin") && (
                <div className=" text-white bg-gray-600 px-3 py-1 rounded-full text-xs inline ml-2">
                  NPC
                </div>
              )}
            </p>
            <p>Population: {cityData.city_population}</p>
          </div>

          <div className="text-center">
            <p>
              (X: {props.position.x} Y: {props.position.y})
            </p>
            {isMyCity ? (
              <div className="">My City</div>
            ) : (
              <div>
                <div>
                  {cityRestrictions.hasBarracks ? (
                    <button
                      className="bg-white text-blue-500 px-4 py-2 rounded shadow-md hover:text-purple-600 focus:outline-none mt-2 text-xs sm:text-sm md:text-base"
                      onClick={() => setActionMode("attack")}
                    >
                      Attack
                    </button>
                  ) : (
                    <div className="text-red-400">
                      You need a barracks in your city to attack!
                    </div>
                  )}
                </div>
                <div>
                  {cityData.username !== "botlin" && (
                    <div>
                      <button
                        className="bg-white text-blue-500 px-4 py-2 rounded shadow-md hover:text-purple-600 focus:outline-none mt-2 text-xs sm:text-sm md:text-base"
                        onClick={() => setActionMode("reinforce")}
                      >
                        Reinforce
                      </button>
                      {cityRestrictions.hasMarketplace ? (
                        <button
                          className="bg-white text-blue-500 px-4 py-2 rounded shadow-md hover:text-purple-600 focus:outline-none mt-2 text-xs sm:text-sm md:text-base"
                          onClick={() => setShowSendResMenu(true)}
                        >
                          Send resources
                        </button>
                      ) : (
                        <div className="text-red-200">
                          You need a marketplace to send resources to other
                          cities!
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        {showSendResMenu && <SendResFromMapCard toCityId={cityData.city_id} />}
      </div>
    );
  }
  return (
    <MapAttackCard
      hideMenu={hideAttackMenu}
      cityId={props.tileData.cityId}
      targetCity={props.tileData.city.cityName}
      targetUser={cityData.username}
      actionMode={actionMode}
      targetUserColor={cityData.namecolor}
      targetUserIcon={cityData.nameicon}
      targetUserTitle={cityData.nametitle}
    />
  );
}
