import { SmallWheatIcon } from "../../images/resources/SmallWheatIcon";
import { SmallWoodIcon } from "../../images/resources/SmallWoodIcon";
import { SmallStoneIcon } from "../../images/resources/SmallStoneIcon";
import { SmallIronIcon } from "../../images/resources/SmallIronIcon";
import { useTimerState } from "../contexts/timerContext";
import { useContext, useEffect, useState } from "react";
import { HelperFunctions } from "../../HelperFunctions";
import { CityConfigContext } from "../../index";
import { InstaBuildingCooldown } from "../InstaBuildingCooldown";
import { RULE_CONFIG } from "../../RULES";
import { InstaBuildTokenButton } from "../InstaBuildTokenButton";
import { AllBuildingOptions } from "../AllBuildingOptions";

export function NextLevelRequirementsCard({
  buildingName,
  level,
  wheatReq,
  woodReq,
  stoneReq,
  ironReq,
  actualBuildTime,
  bonuses,
  hasEnoughResources,
  build,
  canInstaBuild,
}) {
  const { insta_builds_left, cityTokens } = useContext(CityConfigContext);
  const allTimers = useTimerState();

  const [buildingTimers, setBuildingTimers] = useState([
    ...allTimers.resourceTimers,
    ...allTimers.buildingTimers,
  ]);

  useEffect(() => {
    setBuildingTimers([
      ...allTimers.resourceTimers,
      ...allTimers.buildingTimers,
    ]);
  }, [allTimers]);

  return (
    <div className="grid grid-cols-1 place-items-center mt-4">
      <h4 className="text-xl font-semibold mb-2">Upgrade to {level}:</h4>

      <div className="grid grid-cols-2 gap-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4">
        <div className="flex">
          <SmallWheatIcon />{" "}
          <h4>{HelperFunctions.turnNumberToNumberic(wheatReq)}</h4>
        </div>
        <div>
          <div className="flex">
            <SmallWoodIcon />{" "}
            <h4>{HelperFunctions.turnNumberToNumberic(woodReq)}</h4>
          </div>{" "}
        </div>
        <div>
          <div className="flex">
            <SmallStoneIcon />{" "}
            <h4>{HelperFunctions.turnNumberToNumberic(stoneReq)}</h4>{" "}
          </div>{" "}
        </div>
        <div>
          <div className="flex">
            <SmallIronIcon />{" "}
            <h4>{HelperFunctions.turnNumberToNumberic(ironReq)}</h4>
          </div>{" "}
        </div>
      </div>
      <h2 className="text-lg mb-4">
        {HelperFunctions.secondsToTimestamp(actualBuildTime)}{" "}
        {bonuses && bonuses.building_speed_bonus > 0 && (
          <div>({bonuses.building_speed_bonus}% bonus)</div>
        )}
      </h2>
      <div className="">
        <AllBuildingOptions
          bonuses={bonuses}
          build={build}
          buildingTimers={buildingTimers}
          hasEnoughResources={hasEnoughResources}
          buildingName={buildingName}
          nextBuildingLevel={level}
          canInstaBuild={canInstaBuild}
        />
        {/*         {buildingTimers.length <
        RULE_CONFIG.default_buildings_in_queue +
          bonuses.extra_queue_buildings ? (
          <div>
            <div className="flex space-x-4">
              {!hasEnoughResources ? (
                <h4 className="text-red-500 font-bold">Not enough resources</h4>
              ) : (
                <button
                  onClick={() => build(resReqId, false)}
                  className="bg-green-600 hover:bg-green-500 text-white font-semibold py-2 px-4 rounded transition duration-200"
                >
                  Build
                </button>
              )}

              <InstaBuildingCooldown
                build={() => build(resReqId, "normal")}
                builds_left={insta_builds_left}
              />
              <InstaBuildTokenButton build={() => build(resReqId, "token")} />
            </div>

            {bonuses.insta_building_complete_chance > 0 && (
              <h4>
                Roll lower than {bonuses.insta_building_complete_chance} to
                insta build. Does not roll for building already in queue.
              </h4>
            )}
          </div>
        ) : (
          <h4 className="font-bold">Building queue full!</h4>
        )} */}
      </div>
    </div>
  );
}
