import { useState } from "react";
import { TabItem } from "./Marketplace";
import { BuildingDescAndImage } from "../cards/BuildingDescAndImage";
import { BarracksTroopsList } from "./BarracksTroopsList";
import { CombatCalculator } from "./CombatCalculator";

export function Barracks({ level, hideUpgradeMenu }) {
  const [activeTab, setActiveTab] = useState("overview");

  const handleTabChange = (tab) => {
    if (activeTab === "overview") {
      hideUpgradeMenu(false);
    } else {
      hideUpgradeMenu(true);
    }

    setActiveTab(tab);
  };

  return (
    <div>
      <div className="mb-4">
        <ul className="flex border-b">
          <TabItem
            label="Overview"
            active={activeTab === "overview"}
            onClick={() => handleTabChange("overview")}
          />
          <TabItem
            label="Production"
            active={activeTab === "production"}
            onClick={() => handleTabChange("production")}
          />
          <TabItem
            label="Calculator"
            active={activeTab === "calculator"}
            onClick={() => handleTabChange("calculator")}
          />
        </ul>
      </div>

      {activeTab === "overview" && (
        <BuildingDescAndImage type="Barracks" isDetails={true} />
      )}
      {activeTab === "production" && <BarracksTroopsList level={level} />}
      {activeTab === "calculator" && <CombatCalculator />}
    </div>
  );
}
